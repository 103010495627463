<template>
    <lemon-message-basic class="lemon-message-product-link" :class="{ 'lemon-message--reverse': isSelf }" :props="$attrs"
        :message="$attrs.message">
        <template #content>
            <div class="lemon-product-wrapper">
                <div class="lemon-product-item" @click.stop="handleProductClick(product)">
                    <img :src="product.litimg"
                        style="flex: none;border: 1px solid #eee; border-radius: 4px; width: 80px; height: 60px; object-fit: cover;" />
                    <div class="lemon-product-detail">
                        <div class="lemon-product-title" style="color: #000;">{{ product.productName }}</div>
                        <div class="lemon-product-price">
                            <span>{{ product.minPrice }}</span>
                            <template v-if="product.maxPrice && product.maxPrice > product.minPrice">
                                <span>~</span>
                                <span>{{ product.maxPrice }}</span>
                            </template>
                            <span>元</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </lemon-message-basic>
</template>


<script>
export default {
    name: 'lemonMessageProductLink',
    inject: ['IMUI'],
    inheritAttrs: false,
    data() {
        return {
            imageUrl: '',
        }
    },
    computed: {
        isSelf() {
            return this.$attrs.message?.fromUser?.id === this.$store.getters.user?.userId
        },
        product() {
            let p = this.$attrs.message?.content
            try {
                p = JSON.parse(this.$attrs.message?.content)
            }
            catch (e) {
                console.log(e)
            }
            return p
        }
    },
    mounted() {

    },
    methods: {
        handleProductClick(product) {
            this.winOpen(`/mall/${product.productId}`)
        },
    }
}
</script>

<style lang="less">
.lemon-message-product-link {
    .lemon-message__content {
        padding: 0;
        background-color: transparent!important;

        &::before {
            display: none;
        }
    }

    .lemon-product-item {
        display: flex;
        align-items: center;
        border: 1px solid #ddd;
        padding: 5px;
        border-radius: 4px;
        margin-bottom: 10px;
        background-color: #fff;
        max-width: 216px;
        cursor: pointer;

        .lemon-product-detail {
            max-width: 55%;
            margin-left: 5px;
        }

        .lemon-product-title {
            font-size: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .lemon-product-price {
            margin-top: 5px;
            font-size: 12px;
            color: #FA562C;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
</style>