import RouterLayout from "@/layout/router";
export default [
    // 专题： 规则、公告、新手入门
    {
        path: "/doc",
        component: RouterLayout,
        meta: {
            title: "专题",
            icon: "dashboard",
            affix: true,
        },
        children: [
            {
                path: "",
                name: "Doc",
                component: () =>
                    import(/* webpackChunkName: "doc-list" */ "@/views/doc/index"),
                meta: {
                    title: "专题列表",
                    icon: "dashboard",
                    affix: true,
                },
            },
            {
                path: ":id",
                name: "DocDetail",
                component: () =>
                    import(/* webpackChunkName: "doc-detail" */ "@/views/doc/_id"),
                meta: {
                    title: "专题详情",
                    icon: "dashboard",
                    affix: true,
                },
            },
        ],
    },
]