<template>
    <el-dialog :visible.sync="open" width="716px" top="20vh" :close-on-press-escape="false" :close-on-click-modal="false"
        :show-close="false" :destroy-on-close="true" append-to-body custom-class="register-dialog" @close="resetRegister">
        <div class="dialog-title">
            <span>免费注册</span>
            <div class="icon close-icon pointer" @click.stop="handleClose" />
        </div>
        <div class="dialog-content">
            <el-form label-position="right" label-width="110px" label-suffix="：" ref="registerForm" :model="registerForm"
                :rules="registerRules">
                <el-form-item label="手机号" prop="phoneNum">
                    <!-- v-focus -->
                    <el-input v-model.trim="registerForm.phoneNum" placeholder="请输入手机号" :maxlength="11" clearable />
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <div class="flex v-center w100">
                        <el-input v-model.trim="registerForm.code" placeholder="输入验证码" :maxlength="6" class="flex1"
                            clearable />
                        <el-button type="primary" style="width: 100px; padding: 12px 0; border-radius: 0"
                            class="ml10 align-c" :disabled="sent || !registerForm.phoneNum" @click.stop="handleSent">
                            <span v-if="sent">{{ seconds }}s</span>
                            <span v-else-if="sendCount <= 0">获取验证码</span>
                            <span v-else>再次发送</span>
                        </el-button>
                    </div>
                </el-form-item>
                <el-form-item label="输入密码" prop="pwd">
                    <el-input v-model.trim="registerForm.pwd" placeholder="输入密码" :maxlength="16" clearable show-password />
                </el-form-item>
            </el-form>
        </div>
        <div class="agree-wrapper flex v-center h-center">
            <c-checkbox v-model="isAgree" active-color="#409EFF" />
            <div class="agree-list flex v-center ml10">
                <span>本人已阅读并同意</span>
                <span class="agree-item pointer text-line line1" :class="{ last: agreeList.length == idx + 1 }"
                    v-for="(agree, idx) in agreeList" :key="agree.agreeId" @click.stop="openProtocol(agree)">
                    《{{ agree.agreeName }}》
                </span>
                <span>的相关内容</span>
            </div>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" class="w100" @click.stop="submitRegister">
                注册
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { getRegisterCode, userRegister } from "@/api/login";
import { timeout } from "@/utils";
import protocol from "@/mixins/protocol";

export default {
    mixins: [protocol],
    inject: ['updateQuery'],
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            registerForm: {
                phoneNum: "",
                code: "",
                pwd: "",
            },
            registerRules: {
                phoneNum: [
                    { required: true, trigger: "blur", message: "请输入手机号" },
                    {
                        async validator(_, phone) {
                            if (phone && !/^1[3456789]\d{9}$/.test(phone)) {
                                return Promise.reject("请输入正确手机号");
                            }
                            return Promise.resolve(true);
                        },
                        trigger: "blur",
                    },
                ],
                code: [{ required: true, trigger: "blur", message: "请输入验证码" }],
                pwd: [
                    { required: true, trigger: "blur", message: "请输入密码" },
                    {
                        async validator(_, pwd) {
                            if (pwd && !/^[0-9A-Za-z]{6,16}$/.test(pwd)) {
                                return Promise.reject("输入6~16位字母或数字");
                            }
                            return Promise.resolve(true);
                        },
                        trigger: "blur",
                    },
                ],
            },
            sendCount: 0,
            sent: false,
            seconds: 60,
            agreeList: [
                {
                    agreeId: "AGREE24242421",
                    agreeName: "用户注册协议",
                    code: 2,
                },
                {
                    agreeId: "AGREE24242422",
                    agreeName: "隐私政策",
                    code: 1,
                },
                // {
                //     agreeId: "AGREE24242423",
                //     agreeName: "服务协议",
                //     code: 3,
                // },
            ],
            isAgree: false,
        }
    },
    computed: {
        open: {
            set(val) {
                this.$emit('input', val)
            },
            get() {
                return this.value
            }
        }
    },
    methods: {
        resetRegister() {
            this.sendCount = 0;
            this.seconds = 60;
            this.sent = false;
            this.$refs.registerForm?.resetFields();
        },
        handleRegister() {
            this.open = true;
        },
        handleClose() {
            this.open = false;
        },
        async handleSent() {
            if (this.sent) {
                return;
            }
            this.sent = true;
            this.sendCount += 1;
            this.sendCode();
            await this.codeCountDown();
            this.sent = false;
            this.seconds = 60;
        },
        async sendCode() {
            const phoneNum = this.registerForm?.phoneNum;
            if (!phoneNum) {
                return;
            }
            await getRegisterCode({ phoneNum });
        },
        async submitRegister() {
            if (!this.isAgree) {
                return this.$message.warning('请勾选协议')
            }
            const isValid = await this.$refs.registerForm
                ?.validate()
                .catch(console.error);
            if (!isValid) {
                return;
            }
            const params = cloneDeep(this.registerForm) || {};
            const registerRes = await userRegister(params).catch(console.error);
            if (!registerRes) {
                return;
            }
            this.open = false;
            this.$message.success("注册成功!");
            const token = registerRes.token || '';
            if (!token) {
                return
            }
            this.$store.commit('SET_TOKEN', token);
            this.$store.dispatch('GetInfo');
            this.updateQuery && this.updateQuery();
        },
        /*验证码发送倒计时*/
        async codeCountDown() {
            if (this.seconds <= 0) {
                return;
            }
            await timeout(1000);
            this.seconds -= 1;
            await this.codeCountDown();
        },
    }
}
</script>

<style lang="less">
.register-dialog {
    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0 17px 0 24px;

        .dialog-title {
            font-size: 28px;
            color: #4c4c4c;
            font-weight: bold;
            line-height: 40px;
            padding: 30px 0 20px;
            border-bottom: 1px solid #ececec;
            text-align: center;
            position: relative;

            .icon {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center;

                &.close-icon {
                    background-image: url("~@/assets/images/close-icon.png");
                    position: absolute;
                    right: -2px;
                    top: 12px;
                }
            }
        }

        .dialog-content {
            padding: 46px 173px 0 101px;

            .el-form-item__label {
                font-size: 14px;
                color: #4c4c4c;
                // padding-right: 44px;
            }

            .el-form-item {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .el-input__inner {
                border-radius: 0;
            }
        }

        .dialog-footer {
            padding: 30px 100px 50px 92px;
        }
    }

    .agree-wrapper {
        margin-top: 26px;
        padding: 0 52px 0 46px;

        .agree-list {
            color: #8a8a8a;
            font-size: 14px;

            .agree-item {
                color: #409eff;
                display: inline-flex;
                align-items: center;

                &.last {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>