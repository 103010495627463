import request from '@/utils/request'
export default {
  list(data) {
    return request({
      url: `/user/message/page`,
      method: "post",
      data
    });
  },
  read(data) {
    return request({
      url: `/user/message/read`,
      method: "post",
      data
    });
  },
  readAll() {
    return request({
      url: `/user/message/readAll`,
      method: "get",
    });
  },
  count(params = {}) {
    return request({
      url: `/user/message/count`,
      method: "get",
      params
    });
  },
  delete(data) {
    return request({
      url: `/user/message/delete`,
      method: "post",
      data
    });
  },
}