import request from '@/utils/request'
export default {
  list(data = {}) {
    return request({
      url: "/address/list",
      method: "post",
      data,
    });
  },
  detail(addressId) {
    return request({
      url: `/address/list/${addressId}`,
      method: "post",
    });
  },
  save(data) {
    return request({
      url: `/address/save`,
      method: "post",
      data,
    });
  },
  update(data) {
    return request({
      url: `/address/update`,
      method: "post",
      data,
    });
  },
  // 查询省
  getProvinceList() {
    return request({
      url: "/web/provincial/list",
      method: "post",
    });
  },
  // 查询市
  getCityList(cityId) {
    return request({
      url: `/city/list/${cityId}`,
      method: "post",
    })
  },
  // 查询区/县
  getCountyList(countyId) {
    return request({
      url: `/county/list/${countyId}`,
      method: "post",
    });
  },
}
