import request from '@/utils/request'
export default {
  list(params = {}) {
    return request({
      url: '/web/pass/blogroll/list',
      method: 'get',
      params
    })
  }
}
