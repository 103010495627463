import request from '@/utils/request'

// 短信登录方法
export function login(data) {
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}
// 手机号登录方法
export function mobileLogin(data) {
  return request({
    url: '/mobileLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 微信登录方法
export function wechatLogin(params) {
  return request({
    url: '/system/user/wechatLogin',
    headers: {
      isToken: false
    },
    method: 'get',
    params
  })
}

// 发送短信验证码
export function getForgotCode(params) {
  return request({
    url: '/user/register/getForgotPwdCode',
    headers: {
      isToken: false
    },
    method: 'get',
    params
  })
}
// 忘记密码
export function forgotPwd(data) {
  return request({
    url: '/user/register/handleForgotPassword',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 发送短信验证码
export function getRegisterCode(params) {
  return request({
    url: '/user/register/getRegisterCode',
    headers: {
      isToken: false
    },
    method: 'get',
    params
  })
}
export function getLoginCode(params) {
  return request({
    url: '/user/register/getLoginCode',
    headers: {
      isToken: false
    },
    method: 'get',
    params
  })
}

// 注册方法
export function userRegister(data) {
  return request({
    url: '/user/register/submit',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取qrCode
export function getQRCode() {
  return request({
    url: '/user/register/getQRCode',
    method: 'get'
  })
}
// 轮询获取qr信息
export function getQRPolling(params) {
  return request({
    url: '/user/register/getQRPolling',
    method: 'get',
    params
  })
}
// 轮询获取qr信息
export function loginQR(params = {}) {
  return request({
    url: '/user/register/loginQR',
    method: 'get',
    params
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
