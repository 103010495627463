<template>
  <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
    <el-breadcrumb-item
      v-for="(bread, idx) in breadList"
      :key="bread.name"
      :to="idx + 1 == breadList.length ? undefined : { path: bread.path || '/' }"
      :class="[idx + 1 == breadList.length ? 'text-line line1' : '']"
      style="max-width: 33vw"
    >
      <!-- 最后一个面包屑 -->
      <template v-if="idx + 1 == breadList.length">
        <span v-html="title" v-if="title" />
        <span v-else>
          {{ $parent?.title || bread.title }}
        </span>
      </template>
      <template v-else>
        <span>{{ bread.title }}</span>
      </template>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Bread",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    breadList() {
      const params = this.$route.params;
      const routeMatched = this.$route.matched
        .filter((v) => !v.meta?.hidden)
        .map((v) => {
          for (let key in params) {
            v.path = v.path.replace(`:${key}`, params[key]);
          }
          return {
            name: v.name,
            path: v.path,
            title: v.meta?.title || "",
          };
        });
      return cloneDeep(routeMatched);
    },
  },
};
</script>

<style lang="less">
.bread {
  padding: 10px 0;
  background: transparent !important;

  .el-breadcrumb__inner.is-link {
    color: #4c4c4c;
  }
}
</style>
