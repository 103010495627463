<template>
    <lemon-message-basic class="lemon-message-video aaa" :class="{ 'lemon-message--reverse': isSelf }" :props="$attrs"
        :message="$attrs.message">
        <template #content>
            <div class="poster-wrapper">
                <img class="video-poster" :src="imageUrl" style="width: 100%" v-if="imageUrl" />
                <video ref="video" class="video-play" :src="$attrs.message.content"
                    style="position: absolute; z-index: -3; left: 0; top: 0; width: 100%;" />
                <i class="el-icon el-icon-video-play play-icon" @click="handleVideoPlay" />
            </div>
        </template>
    </lemon-message-basic>
</template>


<script>
export default {
    name: 'lemonMessageVideo',
    inject: ['IMUI'],
    inheritAttrs: false,
    data() {
        return {
            imageUrl: '',
        }
    },
    computed: {
        isSelf() {
            return this.$attrs.message?.fromUser?.id === this.$store.getters.user?.userId
        }
    },
    mounted() {
        setTimeout(() => {
            const $attrs = this.$attrs;
            const message = $attrs.message;
            const video = document.createElement('video')
            video.setAttribute('crossOrigin', 'anonymous')
            video.setAttribute('src', message?.content)
            video.setAttribute('controls', 'controls')
            video.setAttribute('preload', true)
            video.style.cssText = "width: 300px; position: fixed; left: -1000vw; top: -1000vw; opacity: 0; z-index: -1000;"
            video.currentTime = 1
            const canvas = document.createElement('canvas')
            const styles = getComputedStyle(video)
            const width = parseFloat(styles.width)
            const height = parseFloat(styles.height)
            const ctx = canvas.getContext('2d')
            ctx.fillStyle = '#000';
            ctx.fillRect(0, 0, width, height)
            const dataURL = canvas.toDataURL('image/jpeg') //转换为base64
            this.imageUrl = dataURL;

            video.addEventListener('loadeddata', () => {
                console.log('sss', message?.content)
                const canvas = document.createElement('canvas')
                const styles = getComputedStyle(video)
                const width = parseFloat(styles.width)
                const height = parseFloat(styles.height)
                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(video, 0, 0, width, height)
                const dataURL = canvas.toDataURL('image/jpeg') //转换为base64
                this.imageUrl = dataURL;
                video.remove()
            })
            document.body.appendChild(video)
        }, 500);
    },
    methods: {
        handleVideoPlay() {
            const player = this.$refs.video;
            console.log(player.requestFullscreen)
            player.requestFullscreen();
            player.play();
        }
    }
}
</script>

<style lang="less">
.lemon-message-video {
    .lemon-message__content {
        padding: 0;
        background-color: transparent!important;

        &::before {
            display: none;
        }

        .poster-wrapper {
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            .play-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 32px;
                color: #fff;
                cursor: pointer;
                z-index: 2;
            }
        }
    }
}
</style>