// 通用组件注册
import VideoPlayer from './common/videoPlayer'
import CourseComment from "./business/courseComment.vue";
import { kebabCase } from 'lodash'
import PostsNodes from './business/postsNodes.vue'

export default {
    install(Vue) {
        const ctx = require.context('./common', true, /.vue$/)
        for (const fileName of ctx.keys()) {
            const componentName = fileName.split('/').pop().replace(/\.vue/, '')
            Vue.component('c-' + kebabCase(componentName), ctx(fileName).default)
        }
        Vue.use(VideoPlayer)
        Vue.component("course-comment", CourseComment);
        Vue.component("posts-nodes", PostsNodes);
    }
}