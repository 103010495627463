<template>
  <div class="editor-wrapper" :class="{ show: show }" style="border: 1px solid #ccc;">
    <w-toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode"
      v-if="!show" />
    <w-editor :style="{ height: height ? height + 'px' : 'auto', minHeight: minHeight ? minHeight + 'px' : 'auto' }"
      style="overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
  </div>
</template>

<script>
import request from 'axios'
import { Editor as wEditor, Toolbar as wToolbar } from '@wangeditor/editor-for-vue';
import '@wangeditor/editor/dist/css/style.css'

export default {
  components: {
    wEditor,
    wToolbar
  },
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: ''
    },
    /* 高度 */
    height: {
      type: Number,
      default: null
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    }
  },
  data() {
    const readOnly = this.readOnly || this.show;
    return {
      editor: null,
      toolbarConfig: { excludeKeys: ['fullScreen'] },
      editorConfig: {
        placeholder: '请输入内容...',
        readOnly: readOnly,
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              const fd = new FormData();
              fd.append('file', file);
              const res = await request({ url: '/upload', method: 'post', data: fd, headers: { "Content-Type": "multipart/form-data" } }).catch(console.error)
              if (!res) {
                return
              }
              const url = res.data;
              insertFn(url, file.name || file.fileName, url)
            }
          },
          uploadVideo: {
            async customUpload(file, insertFn) {
              const fd = new FormData();
              fd.append('file', file);
              const res = await request({ url: '/upload', method: 'post', data: fd, headers: { "Content-Type": "multipart/form-data" } }).catch(console.error)
              if (!res) {
                return
              }
              const url = res.data;
              insertFn(url, file.name || file.fileName, url)
            }
          }
        }
      },
    }
  },
  computed: {
    html: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    // 组件销毁时，及时销毁编辑器
    const editor = this.editor
    if (!editor) {
      return;
    }
    editor.destroy()
  }
}

</script>

<style>
.editor-wrapper {
  .w-e-image-container {
    margin: -6px 0;
  }
}

.editor-wrapper.show {
  border: none !important;
}
</style>