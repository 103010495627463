import router from '@/router'
import store from '@/store'
import lodash from 'lodash'
import { timeout } from '@/utils'
import { requestAlive } from '@/utils/request';

function collectEls(fn, selector = '[data-collected]') {
    const els = document.body.querySelectorAll(selector)
    for (let el of els) {
        el.addEventListener('click', function (...args) {
            setTimeout(() => collectEls(fn, selector), 500);
            fn.call(this, ...args)
        })
    }
}

export default {
    source: 1,
    _init: false,
    _currentPage: null,
    _previousPage: null,
    _uid: null,
    isSending: false,
    init() {
        if (this._init) {
            return
        }
        this._init = true;
        const self = this;
        const throttleFn = lodash.throttle(function (e) {
            console.log('click', e, this.getAttribute('data-collected'), self._currentPage)
            const paramsStr = this.getAttribute('data-collected')
            const params = paramsStr.split('&').reduce((prev, current) => {
                const [key, val] = current.split('=');
                prev[key] = val
                return prev;
            }, {})
            self.send(params)
        }, 1000, { trailing: true, leading: false })
        router.afterEach(async (to, from) => {
            this._currentPage = to;
            this._previousPage = from;
            this._uid = store.getters.user?.userId || null;
            await timeout(1200);
            // 标记埋点
            collectEls(throttleFn);
            // 全埋点
            // document.body.addEventListener('click', throttleFn, true)
        })
    },
    send(params) {
        const path = this._currentPage?.fullPath || ''
        this._uid = store.getters.user?.userId || null;
        const userId = this._uid

        params = { ...params, userId, path, loginSource: 1 };

        if (params.pv) {
            if (params.path == '/') {
                if (this.isSending) {
                    return
                }
                this.isSending = true;
                return requestAlive({
                    method: 'post',
                    data: { ...params, menuIndex: path },
                    url: '/web/platformPv/add'
                }).finally(() => this.isSending = false)
            }
            return;
        }

        return requestAlive({
            method: 'post',
            data: params,
            url: '/web/buryingPoint/log/save'
        }).catch(console.error)
    },
    install(Vue) {
        Vue.prototype.$collection = this;
        window.collection = this;
    }
}