<template>
  <div class="layout-container flex col">
    <div v-if="this.$route.name === 'Home'" class="popup-container">
      <div v-if="leftAd.length>0" class="side-container lt">
        <div class="btn-close">
          <el-image :src="require(`@/assets/images/close-w.png`)" @click.stop="leftAd=[]" style="cursor: pointer; width: 20px;" />
        </div>
        <el-carousel height="238px">
          <el-carousel-item v-for="(item, i) in leftAd" :key="i">
            <el-image :src="item.image" style="width: 100%;height: 100%; cursor: pointer;" fit="fill" @click="openWindow(item.url)"/>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div v-if="rightAd.length>0" class="side-container rt">
        <div class="btn-close">
          <el-image :src="require(`@/assets/images/close-w.png`)" @click.stop="rightAd=[]" style="cursor: pointer; width: 20px;" />
        </div>
        <el-carousel height="238px">
          <el-carousel-item v-for="(item, i) in rightAd" :key="i">
            <el-image :src="item.image" style="width: 100%;height: 100%; cursor: pointer;" fit="fill" @click="openWindow(item.url)"/>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div v-if="middleAd.length>0" style="position: fixed;width: 100%;height: 100%;z-index: 114;background-color: #000000; opacity: .3"></div>
      <div v-if="middleAd.length>0" class="middle-container">
        <div style="position: relative;width: 800px;height: 400px;">
          <div class="mbt-close">
            <el-image :src="require(`@/assets/images/close-w.png`)" @click.stop="middleAd=[]" style="cursor: pointer;" />
          </div>
          <el-carousel height="400px">
            <el-carousel-item v-for="(item, i) in middleAd" :key="i">
              <el-image :src="item.image" style="width: 100%;height: 100%; cursor: pointer;" fit="fill" @click="openWindow(item.url)"/>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <c-header ref="header" />
    <anchor />
    <div class="app-main flex1">
      <transition name="fade-transform" mode="out-in">
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </transition>
    </div>
    <c-footer />
  </div>
</template>

<script>
import Anchor from '@/components/business/anchor.vue';
import adApi from '@/api/ad';
import storage from '@/utils/storage'
import dayjs from "dayjs";
export default {
  components: { Anchor },
  data() {
    return {
      leftAd: [],
      rightAd: [],
      middleAd: [],
    }
  },
  created() {
    if (this.$route.name === 'Home') {
      this.fetchAdSide();
      this.fetchAdMiddle();
    }
  },
  methods: {
    async fetchAdSide() {
      const res = await adApi.advertiseList({adType: 3, isStart: 1, pageSize: 100, pageNum: 1}).catch(console.error)
      if (!res || res?.data.length <= 0) {
        return;
      }
      let leftAd = [];
      let rightAd = [];
      res.data.list.forEach(item => {
        if (item.index == 6) {
          leftAd.push({image: item.image, url: item.skipUrl})
        } else {
          rightAd.push({image: item.image, url: item.skipUrl})
        }
      })
      let _that = this
      setTimeout(function () {
        _that.leftAd = leftAd;
        _that.rightAd = rightAd;
      },1500)
    },
    async fetchAdMiddle() {
      if (storage.get('middlePopupAdvert')) {
        return;
      }

      const res = await adApi.advertiseList({adType: 2, isStart: 1, pageSize: 100, pageNum: 1}).catch(console.error)
      if (!res || res?.data.length <= 0) {
        return;
      }
      let list = [];
      res.data.list.forEach(item => {
          list.push({image: item.image, url: item.skipUrl})
      })
      let _that = this
      setTimeout(function () {
        _that.middleAd = list;

        if (_that.middleAd.length>0) {
          let date = new Date();
          date.setDate(date.getDate() + 1);
          storage.set('middlePopupAdvert', 'middlePopupAdvert', new Date(dayjs(date).format("YYYY-MM-DD 00:00:00")).getTime() - Date.now());
        }
      },1500)
    },
    openWindow(url) {
      if (!url) {
        return
      }
      this.winOpen(url, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>


.layout-container {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--layout-bg-color);

  .app-main {
    width: var(--layout-width);
    margin: 0 auto;
  }

  .popup-container {

    ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      opacity: 0.3;
    }
    ::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button{
      width: 5px;
      height: 5px;
      border-radius: 50%;
      opacity: 1;
    }

    .side-container {
      position: absolute;
      width: 180px;
      height: 238px;
      top: 255px;
      z-index: 100;

      &:hover .btn-close {
        opacity: 1;
      }
    }

    .rt {
      right: 88px;
    }

    .lt {
      left: 11px;
    }

    .btn-close {
      position: absolute;
      opacity: 0;
      top: -20px;
      right: -20px;
      transition-duration: 1s;

    }

    .middle-container {
      position: fixed;
      left: 50%;
      top: 50%;
      margin-left: -400px;
      margin-top: -200px;
      z-index: 115;

      &:hover .mbt-close{
        opacity: 1;
      }

    }
    .mbt-close {
      position: absolute;
      opacity: 0;
      top: -40px;
      right: -40px;
      transition-duration: 1s;
    }
  }
}
</style>
