<script>
export default {
    props: ['postsNodes', 'receiver', 'comment'],
    data() {
        return {
            nodeList: []
        }
    },
    watch: {
        postsNodes: {
            deep: true,
            immediate: true,
            handler(list = []) {
                this.nodeList = list.map(v => ({ ...v, showComment: false, commentCtx: 'aaa' }));
            }
        }
    },
    render() {
        const nodes = this.nodeList || []
        const receiver = this.receiver || {};
        const comment = this.comment || {};
        const parseTime = (date) => this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss')
        const emit = this.$emit.bind(this);

        return (<div class="posts-nodes-view">
            {
                nodes.map(v =>
                    <div style="margin-top: 10px;">
                        <el-row gutter={20} style="color: #333; line-height: 30px" class="comment-hide">
                            <el-col span={22} class="flex">
                                {v.sysUser ? <div style="max-width: 100px" class="font14 text-line line1 flex0">{v.sysUser.nickName}</div> : ''}
                                {v.userMemberLogo ? <img class="ml5 flex0" alt={v.sysUser ? v.sysUser.nickName : ''} src={v.userMemberLogo} style="width:30px;height:30px;object-fit:contain;" /> : ''}
                                <div class="font14 flex0" style="margin: 0 4px;color: #333;">
                                    <i class="el-icon-arrow-right" />
                                </div>
                                <div class="font14 text-line line1 flex0" style="max-width: 100px">{receiver.nickName}</div>
                                {comment.userMemberLogo ? <img class="ml5 flex0 mr5" alt={receiver.nickName} src={comment.userMemberLogo} style="width:30px;height:30px;object-fit:contain;" /> : ''}
                            </el-col>
                            <el-link type="primary" class="ml5 reply-btn flex0" underline={false} onClick={(e) => { e.preventDefault(); v.showComment = !v.showComment; v.commentCtx = '' }}>
                                回复
                            </el-link>
                            <div class="font14 ml10" style="word-break: break-all; color:#999">{v.context}</div>
                            <el-col span={24} style="color: #999; margin-top:3px;">
                                <div class="font12">{parseTime(v.createTime)}</div>
                            </el-col>
                            <el-col span={24}>
                                {v.showComment ?
                                    <div class="mt10">
                                        <el-input size="small" value={v.commentCtx} onInput={val => v.commentCtx = val}>
                                            <div class="pointer" slot="append" onClick={e => { e.preventDefault(); emit('submit', v) }}>
                                                <span>回复</span>
                                            </div>
                                        </el-input>
                                    </div> : ''
                                }
                            </el-col>
                        </el-row>
                        {v.postsNodes ? <posts-nodes comment={v} receiver={v.sysUser} posts-nodes={v.postsNodes} onSubmit={v => { emit('submit', v) }} /> : ''}
                    </div >
                )
            }
        </div >)
    },
}
</script>