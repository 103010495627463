import { cloneDeep } from 'lodash'
export default {
    methods: {
        yg_submit(event, ...args) {
            let deep = cloneDeep(args).pop()
            if (deep <= 0 && typeof deep == 'number') {
                return this.$emit(event, ...args)
            }
            let parent = this.$parent
            let current = this
            const isNumber = typeof deep === 'number'
            let condition = isNumber ? (parent && deep >= 1) : (parent && !this._isRoute(parent))
            while (condition) {
                current = parent
                parent = parent.$parent;
                if (isNumber) {
                    deep -= 1;
                }
                condition = isNumber ? (parent && deep >= 1) : (parent && !this._isRoute(parent))
            }
            current.$emit(event, ...args)
        },
        _isRoute(vueComponent) {
            if (!vueComponent) {
                return false
            }
            const routes = this.$route.matched || []
            for (const route of routes) {
                if (route.instances?.default?._uid == vueComponent._uid) {
                    return true
                }
            }
            return false
        }
    }
}