export default [
    // 合作商列表
    {
        path: "/cooperation",
        component: () =>
            import(/* webpackChunkName: "brand-list" */ "@/views/cooperation/index"),
        name: "CooperationList",
        meta: {
            title: "寻找合作",
            icon: "dashboard",
            affix: true,
        },
    },
]