<template>
  <el-dialog :visible.sync="open" width="791px" top="10vh" title="" :close-on-press-escape="false" border-radius="10px"
    :close-on-click-modal="false" :destroy-on-close="true" custom-class="open-shop-dialog">
    <div class="step-wrapper">
      <div class="title">入驻流程</div>
      <img src="@/assets/images/into-shop.png" alt="">
    </div>
    <div class="next-btn-wrapper">
      <div @click.stop="handleNext">立即入驻</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    next: {
      type: String,
      default: "/shop/open",
    },
  },
  data() {
    return {
      step: 0,
      identity: "1",
      inviteCode: '',
      initInviteCode: ''
    };
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleNext() {
      if (this.inviteCode && !/^[0-9a-zA-Z]{4}$/.test(this.inviteCode)) {
        return this.$message.warning('邀请码为4位数字或字母')
      }
      this.open = false;
      this.winOpen(`${this.next}?i=${this.identity}${this.inviteCode ? '&c=' + this.inviteCode : ''}`);
    },
  },
  created() {
    const i = this.$storage.get('i')
    if (!i) {
      return
    }
    this.inviteCode = this.$route.query.i || i;
    this.initInviteCode = this.inviteCode;
  }
};
</script>

<style lang="less">
.open-shop-dialog {
  .el-dialog__header {
    padding: 40px 20px 10px 40px;

    .el-dialog__close {
      font-size: 20px !important;
    }
  }

  .el-dialog__body {
    padding: 0 40px 48px 40px;
  }

  .step-wrapper {

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 45px;
    }
  }

  .next-btn-wrapper {
    margin-top: 45px;
    display: flex;
    justify-content: center;

    div {
      width: 200px;
      height: 38px;
      padding: 8px auto;
      background-color: #F36D2CFF;
      color: #fff;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
