<template>
    <lemon-message-basic class="lemon-message-file" :class="{ 'lemon-message--reverse': isSelf }" :props="$attrs"
        :message="$attrs.message">
        <template #content>
            <div class="file-wrapper">
                <div style="margin-right: 10px;">
                    <div class="file-name" style="font-size: 13px; color: #333;">{{ fileName }} </div>
                    <div style="margin-top: 5px; color: #999; font-size: 12px;">{{ getFileSizeLabel(fileSize) }} </div>
                </div>
                <img style="flex: none; background: transparent;" width="40"
                    :src="require(`@/assets/images/chat/${fileType}.png`)" v-if="fileType" />
                <i class="el-icon-paperclip" style="flex: none;font-size: 22px;" v-else />
            </div>
        </template>
    </lemon-message-basic>
</template>


<script>
const fileTypeMap = {
    doc: 'word',
    docx: 'word',
    xlsx: 'excel',
    xls: 'excel',
    ppt: 'ppt',
    pptx: 'ppt',
    pdf: 'pdf',
    zip: 'zip',
    rar: 'zip',
    exe: 'exe',
}

export default {
    name: 'lemonMessageFile',
    inject: ['IMUI'],
    inheritAttrs: false,
    data() {
        return {
            imageUrl: '',
            fileSize: 0
        }
    },
    computed: {
        isSelf() {
            return this.$attrs.message?.fromUser?.id === this.$store.getters.user?.userId
        },
        fileUrl() {
            return this.$attrs.message?.content || '';
        },
        fileName() {
            return this.fileUrl.split('/').pop()
        },
        fileType() {
            const fileExt = this.fileName.replace(/.+\.([a-zA-Z0-9]+)$/, '$1')
            return fileTypeMap[fileExt] || 'unknown'
        }
    },
    methods: {
        getFileSizeLabel(fileSize) {
            if (fileSize < 1024) {
                return Math.floor(fileSize * 10) / 10 + 'B'
            }
            if (fileSize < Math.pow(1024, 2)) {
                return Math.floor(fileSize / Math.pow(1024, 1) * 10) / 10 + 'K'
            }
            if (fileSize < Math.pow(1024, 3)) {
                return Math.floor(fileSize / Math.pow(1024, 2) * 10) / 10 + 'M'
            }
            if (fileSize < Math.pow(1024, 4)) {
                return Math.floor(fileSize / Math.pow(1024, 3) * 10) / 10 + 'G'
            }
            if (fileSize < Math.pow(1024, 5)) {
                return Math.floor(fileSize / Math.pow(1024, 4) * 10) / 10 + 'T'
            }
            return fileSize + 'B'
        }
    },
    async mounted() {
        const fileRes = await fetch(this.fileUrl);
        const fileBlob = await fileRes.blob();
        this.fileSize = fileBlob.size;
    }
}
</script>

<style lang="less">
.lemon-message-file {
    .lemon-message__content {
        background-color: #fff !important;
        border: 1px solid #eee;
        width: 220px;
    }

    .file-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .file-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #000;
    }
}
</style>