import dictsApi from "@/api/dicts";
const Dicts = {};

export default {
    created() {
        this.fetchDicts();
    },
    data() {
        return {
            dicts: {}
        }
    },
    methods: {
        fetchDicts() {
            const dictTypes = this.$options.dicts || [];
            if (dictTypes.length <= 0) {
                return
            }
            dictTypes.forEach(dictType => {
                const dictVal = Dicts[dictType] || [];
                Dicts[dictType] = dictVal
                this.$set(this.dicts, dictType, dictVal);
                if (dictVal.length > 0) {
                    return
                }

                this.fetchDict(dictType).then(list => {
                    if (!list) {
                        return
                    }
                    Dicts[dictType] = list;
                    this.$set(this.dicts, dictType, list);
                });
            })
        },
        async fetchDict(dictType) {
            if (!dictType) {
                return
            }
            const res = await dictsApi.getDicts(dictType);
            return res.data || []
        }
    }
}