<template>
  <div class="anchor-wrapper pos-fix" :class="{ 'has-back-top': canBack }">
    <div
      class="anchor-item font12 flex  h-center v-center pointer pos-rel"
      @click.stop="showFeedback = true"
    >
      <img src="@/assets/images/anchor/feedback_icon.png" class="anchor-icon" />
      <div class="anchor-title font12">意见反馈</div>
    </div>
    <div
      class="anchor-item font12 flex  h-center v-center pointer pos-rel"
      @click.stop="showService = true"
    >
      <img src="@/assets/images/anchor/need_icon.png" class="anchor-icon" />
      <div class="anchor-title font12">Saas需求</div>
    </div>
    <div
      class="anchor-item font12 flex  h-center v-center pointer pos-rel"
      @click.stop="winOpen('/member/center')"
    >
      <img src="@/assets/images/anchor/mc_icon.png" class="anchor-icon" />
      <div class="anchor-title font12">会员中心</div>
    </div>
    <div class="anchor-item font12 flex  h-center v-center pointer pos-rel">
      <img src="@/assets/images/anchor/applet_icon.png" class="anchor-icon" />
      <div class="anchor-title font12">小程序</div>
      <div class="anchor-hover hover1">
        <img
          src="@/assets/images/anchor/yg_applet.jpg"
          width="100"
          height="100"
        />
      </div>
    </div>
    <div
      class="anchor-item font12 flex  h-center v-center pointer pos-rel"
      @click.stop="openMyChat"
    >
      <div class="dot-chat-wrapper" :class="{ unread: haveUnRead }">
        <img src="@/assets/images/anchor/chat_icon.png" class="anchor-icon" />
      </div>
      <div class="anchor-title font12">聊天消息</div>
    </div>
    <div class="anchor-item font12 flex  h-center v-center pointer pos-rel">
      <img src="@/assets/images/anchor/contact_icon.png" class="anchor-icon" />
      <div class="anchor-title font12">客服热线</div>
      <div class="anchor-hover">
        {{ systemConfig.phone }}
      </div>
    </div>
    <div
      class="anchor-back-top flex col h-center v-center pointer"
      :style="{ display: canBack ? 'flex' : 'none' }"
      @click.stop="handleBackTop"
      v-if="backTop"
      style="margin-top: 1px;"
    >
      <img
        src="@/assets/images/anchor/top_icon.png"
        class="anchor-icon"
        style="width: 22px; height: 22px;"
      />
      <div class="top font12">置顶</div>
    </div>
    <service-require-dialog v-model="showService" />
    <feedback-dialog v-model="showFeedback" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { SYSTEM_CONFIG } from "@/store/constant";
import serviceRequireDialog from "./serviceRequireDialog";
import feedbackDialog from "./feedbackDialog";
import chat from "@/mixins/chat";
import chatApi from "@/api/chat";
import { mapGetters } from "vuex";
export default {
  name: "Anchor",
  components: { serviceRequireDialog, feedbackDialog },
  mixins: [chat],
  inject: ["toBackStage"],
  props: {
    backTop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      canBack: false,
      showService: false,
      showFeedback: false,
      haveUnRead: false
    };
  },
  computed: {
    ...mapGetters(["isLogin", "userId"]),
    systemConfig() {
      return this.$store.state[SYSTEM_CONFIG] || {};
    }
  },
  watch: {
    $route() {
      this.updateUnRead();
    }
  },
  methods: {
    async updateUnRead() {
      if (!this.isLogin) {
        return;
      }
      const res = await chatApi
        .getUnreadFlag({ userId: this.userId })
        .catch(console.error);
      if (!res) {
        return;
      }
      this.haveUnRead = res.data ?? false;
    },
    watchAppScroll() {
      const app = document.getElementById("app");
      if (!app) {
        return;
      }
      app.addEventListener("scroll", this.appScrollEvent);
    },
    removeWatchScroll() {
      const app = document.getElementById("app");
      if (!app) {
        return;
      }
      app.removeEventListener("scroll", this.appScrollEvent);
    },
    appScrollEvent() {
      debounce(() => {
        const app = document.getElementById("app");
        if (!app) {
          return;
        }
        this.updateCanBack(app.scrollTop > 20);
      }, 300)();
    },
    updateCanBack(canBack) {
      this.canBack = canBack;
    },
    handleBackTop() {
      const app = document.getElementById("app");
      if (!app) {
        return;
      }
      app.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  mounted() {
    this.updateUnRead();
    this.watchAppScroll();
  },
  beforeDestroy() {
    this.removeWatchScroll();
  },
  deactivated() {
    this.removeWatchScroll();
  },
  activated() {
    this.watchAppScroll();
  }
};
</script>

<style lang="less" scoped>
@anchor-top: calc(
  var(--search-height) + var(--tab-height) + var(--header-height)
);

.anchor-wrapper {
  right: 8px;
  bottom: 50%;
  transform: translateY(50%);
  box-shadow: 0 0 4px 2px rgba(97, 105, 119, 0.18);
  background-color: #fff;
  border-radius: 18px 0 0 18px;
  z-index: 10;
  --anchor-w: 64px;
  --anchor-h: 64px;

  .anchor-item {
    width: var(--anchor-w);
    height: var(--anchor-h);
    color: #333;
    z-index: 11;
    display: flex;
    flex-direction: column;
    margin-top: 1px;

    .dot-chat-wrapper {
      position: relative;

      &::before {
        width: 10px;
        height: 10px;
        background: linear-gradient(90deg, #ff7b4f 0.04%, #f22929 100%);
        display: none;
        content: " ";
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 50%;
        z-index: 3;
      }

      &.unread {
        &::before {
          display: block;
        }
      }
    }

    .anchor-hover {
      width: 0;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fff;
      bottom: 0;
      font-size: 14px;
      color: #000;
      transform: translateX(-100%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      overflow: hidden;
      transition: width 0.2s ease-in-out;

      &.hover1 {
        display: none;
        width: 110px;
        padding: 5px;
        height: 110px;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        border-right: 1px solid #eee;
        transition: none;
      }
    }

    .anchor-icon {
      filter: brightness(0.05);
      width: 24px;
      height: 24px;
      margin-bottom: 6px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      .anchor-hover {
        width: 200%;
        padding: 0 5px;

        &.hover1 {
          display: block;
          width: 110px;
          padding: 5px;
        }
      }
    }
  }

  .anchor-back-top {
    width: var(--anchor-w);
    height: var(--anchor-h);
    position: absolute;
    box-shadow: 0 5px 4px 2px rgba(97, 105, 119, 0.18);
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    color: #333;
    background-color: inherit;
    border-radius: 0 0 0 18px;
    .anchor-icon {
      filter: brightness(0.05);
      width: 24px;
      height: 24px;
      margin-bottom: 6px;
    }
  }

  &.has-back-top{
    border-radius: 18px 0 0 0;
  }
}
</style>
