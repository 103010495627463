<template>
    <div class="teleport-wrapper" :id="id">
        <slot name="default" />
    </div>
</template>

<script>
export default {
    props: {
        to: {
            type: String
        }
    },
    data() {
        return { id: '' }
    },
    created() {
        this.id = 'teleport-' + Date.now().toString(16)
    },
    async mounted() {
        this.once = true
        await this.$nextTick()
        const el = document.querySelector(this.to)
        const currentWrapper = document.querySelector('#' + this.id)
        if (!el || !currentWrapper) {
            return
        }

        const elNodes = el.childNodes
        for (let elNode of elNodes) {
            if ([...elNode.classList].find(v => v.indexOf('teleport-') >= 0)) {
                return
            }
        }

        const childNodes = currentWrapper.childNodes
        for (let node of childNodes) {
            node.classList.add(this.id)
            el.appendChild(node)
        }
        currentWrapper.remove()
    },
    beforeDestroy() {
        const nodes = document.querySelectorAll('.' + this.id)
        for (let node of nodes) {
            node.remove()
        }
    }
}
</script>

<style lang="less">
.teleport-wrapper {
    position: fixed;
    z-index: -1000;
    left: -1000px;
    top: -1000px;
    display: none;
}
</style>