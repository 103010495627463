<template>
  <div
    class="checkbox-wrapper pointer"
    :class="[size]"
    :style="{ borderColor: activeColor }"
  >
    <div
      class="checkbox__inner"
      :class="{ active: checked }"
      :style="{
        backgroundColor: checked ? activeColor : color,
      }"
      @click.stop="() => (checked = !checked)"
    />
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "red",
    },
    color: {
      type: String,
      default: "#fff",
    },
    size: {
      type: String,
      default: "normal",
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.checkbox-wrapper {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100% !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .checkbox__inner {
    width: 50%;
    height: 50%;
    background-color: #ffffff;
    border-radius: 100%;
    &.active {
      background-color: #00c2f5;
    }
  }
  &.small {
    transform: scale(0.6, 0.6);
    transform-origin: center;
  }
  &.mini {
    transform: scale(0.5, 0.5);
    transform-origin: center;
  }
}
</style>