<template>
    <div class="player-wrapper">
        <video ref="video" :id="id" class="video-js" width="830px" height="450px" />
    </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import videoJs from 'video.js'
import lang_zhCN from 'video.js/dist/lang/zh-CN.json'
videoJs.addLanguage('zh-CN', lang_zhCN)
export default {
    props: {
        src: {
            type: String
        },
        poster: {
            type: String,
            default: ''
        },
        language: {
            type: String,
            default: 'zh-CN'
        },
        fluid: {
            type: Boolean,
            default: true
        },
        muted: {
            type: Boolean,
            default: false
        },
        inactivityTimeout: {
            type: Boolean,
            default: false
        },
        preload: {
            type: String,
            default: 'auto'
        },
        // 0~1
        volume: {
            type: Number,
            default: 0.5
        },
        controls: {
            type: Boolean,
            default: true
        },
        controlBar: {
            type: Object,
            default: () => ({
                currentTimeDisplay: false,
                timeDivider: false,
                durationDisplay: false,
                remainingTimeDisplay: false,
                volumePanel: {
                    inline: false,
                }
            })
        }
    },
    data() {
        return {
            player: null,
            id: `player_${Date.now().toString(16)}`,
            options: {
                src: '',
                poster: ''
            }
        }
    },
    watch: {
        src: {
            handler(val) {
                if (!val) {
                    return
                }
                this.init()
            },
            immediate: true
        }
    },
    methods: {
        async init() {
            await this.$nextTick()
            const { src, poster, player, options, id, controls, volume, preload, language, fluid, muted, inactivityTimeout, controlBar } = this
            if (!src) {
                return
            }
            if (player && options.src == src) {
                return
            }
            this.options = { src, poster }
            const el = document.getElementById(id)
            if (!el) {
                return
            }
            const elStyles = getComputedStyle(el)
            this.player = videoJs(el, { controls, poster, src, width: parseInt(elStyles.width), height: parseInt(elStyles.height), preload, language, fluid, muted, inactivityTimeout, controlBar }, () => {
                this.player.src(src);
                ['ready', 'play', 'pause', 'seeking', 'timeupdate', 'fullscreenchange', 'error'].forEach(event => this.player.on(event, (...args) => this.$emit(event, this.player, ...args)));
                + volume && this.player.volume(volume);
            });
            this.player.videoEl = this.$refs.video
        }
    }
}
</script>

<style lang="less">
.player-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .video-js {
        position: static;
        overflow: hidden;
    }

    img {
        object-fit: cover;
    }
}
</style>