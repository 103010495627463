import request from '@/utils/request'
export default {
    // 注册
    getConfig() {
        return request({
            url: "/web/pass/sys/config/get",
            method: "get",
        });
    },
    partnerList() {
        return request({
            url: '/web/pass/partner/list',
            method: "get",
        })
    }
}