export default [
    {
        path: "/application/download",
        component: () =>
            import(
            /* webpackChunkName: "application-download" */ "@/views/application/download"
            ),
        name: "ApplicationDownload",
        meta: {
            title: "应用下载",
            icon: "dashboard",
            affix: true,
        },
    },
]