import protocolApi from '@/api/protocol'
export default {
    methods: {
        async fetchProtocol(code) {
            if (!code) {
                return
            }
            const protocolRes = await protocolApi.queryDetailByType({ type: code }).catch(console.error)
            if (!protocolRes) {
                return
            }
            return protocolRes.data || ''
        },
        async openProtocol(agree) {
            if (!agree) {
                return
            }
            if (!agree.code) {
                return
            }
            const code = agree.code || ''
            const protocol = await this.fetchProtocol(code)
            if (!protocol) {
                return this.$message.warning(`请联系客服设置${agree.agreeName}`)
            }
            this.winOpen(protocol)
        }
    },
}