import request from '@/utils/request'
export default {
    // 获取平台协议设置
    queryDetailByType(params) {
        return request({
            url: "/web/protocol/queryDetailByType",
            method: "get",
            params,
        });
    },
}
