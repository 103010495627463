import RouterLayout from "@/layout/router.vue";
import { re } from "semver";

export default [
  {
    path: "/mall",
    component: () => import(/* webpackChunkName: "mall" */ "@/tabs/mall"),
    name: "Mall",
    redirect: "/mall/retailMall",
    meta: {
      title: "优管商城",
      icon: "dashboard"
    },
    children: [
      {
        path: "retailMall",
        component: RouterLayout,
        meta: {
          title: "管道交易",
          icon: "dashboard"
        },
        children: [
          {
            path: "",
            name: "RetailMall",
            component: () =>
              import(
                /* webpackChunkName: "retail-mall" */ "@/views/mall/retailMall"
              ),
            meta: {
              title: "管道交易"
            }
          },
          {
            path: "list",
            name: "RetailMallList",
            component: () =>
              import(
                /* webpackChunkName: "retail-mall-list" */ "@/views/mall/retailMall/list"
              ),
            meta: {
              title: "商品列表"
            }
          },
          {
            path: ":id",
            name: "RetailMallDetail",
            component: () =>
              import(
                /* webpackChunkName: "retail-mall-detail" */ "@/views/mall/retailMall/_id"
              ),
            meta: {
              title: "商品详情"
            }
          }
        ]
      },
      {
        path: "plasticTrade",
        component: RouterLayout,
        meta: {
          title: "塑料贸易",
          icon: "dashboard"
        },
        children: [
          {
            path: "",
            name: "PlasticTrade",
            component: () =>
              import(
                /* webpackChunkName: "plastic-trade" */ "@/views/mall/plasticTrade"
              ),
            meta: {
              title: "塑料贸易"
            }
          },
          {
            path: "list",
            name: "PlasticTradeList",
            component: () =>
              import(
                /* webpackChunkName: "plastic-trade-list" */ "@/views/mall/plasticTrade/list"
              ),
            meta: {
              title: "商品列表"
            }
          },
          {
            path: ":id",
            name: "PlasticTradeDetail",
            component: () =>
              import(
                /* webpackChunkName: "plastic-trade-detail" */ "@/views/mall/plasticTrade/_id"
              ),
            meta: {
              title: "商品详情"
            }
          }
        ]
      },
      {
        path: "machineDevice",
        component: RouterLayout,
        meta: {
          title: "机电材料",
          icon: "dashboard"
        },
        children: [
          {
            path: "",
            name: "MachineDevice",
            component: () =>
              import(
                /* webpackChunkName: "machine-device" */ "@/views/mall/machineDevice"
              ),
            meta: {
              title: "机电材料"
            }
          },
          {
            path: "list",
            name: "MachineDeviceList",
            component: () =>
              import(
                /* webpackChunkName: "machine-device-list" */ "@/views/mall/machineDevice/list"
              ),
            meta: {
              title: "商品列表"
            }
          },
          {
            path: ":id",
            name: "MachineDeviceDetail",
            component: () =>
              import(
                /* webpackChunkName: "machine-device-detail" */ "@/views/mall/machineDevice/_id"
              ),
            meta: {
              title: "商品详情"
            }
          }
        ]
      },
      {
        path: "list",
        name: "MallList",
        component: () =>
          import(/* webpackChunkName: "mall-list" */ "@/views/mall/list"),
        meta: {
          title: "商品列表"
        }
      },
      {
        path: ":id",
        name: "MallDetail",
        component: () =>
          import(/* webpackChunkName: "mall-detail" */ "@/views/mall/_id"),
        meta: {
          title: "商品详情"
        }
      }
    ]
  },
  {
    path: "/product/:id",
    redirect: "/mall/:id",
    meta: {
      title: "商品详情",
      icon: "dashboard",
      hidden: true
    }
  }
];
