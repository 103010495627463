import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout/default";
import { getToken } from "@/utils/auth";
import store from "@/store";
import { SYSTEM_CONFIG } from "@/store/constant";
import storage from '@/utils/storage'
import modulesRoutes from "./modules";
import request from '@/utils/request'
import event from "@/utils/event";

Vue.use(Router);

// 防止连续点击多次路由报错
["push", "replace"].forEach((name) => {
  const originMethods = Router.prototype[name];
  Router.prototype[name] = function (location) {
    return originMethods.call(this, location).catch((err) => err);
  };
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/login",
      component: () => import("@/views/login"),
      hidden: true,
    },
    {
      path: "/404",
      component: () => import("@/views/error/404"),
      hidden: true,
    },
    {
      path: "/401",
      component: () => import("@/views/error/401"),
      hidden: true,
    },
    {
      path: "/chat",
      component: () => import("@/views/chat/index"),
      hidden: true,
    },
    {
      path: "/my/chat",
      component: () => import("@/views/chat/my"),
      hidden: true,
    },
    {
      path: "",
      component: Layout,
      redirect: "/",
      children: [
        ...modulesRoutes,
      ],
      meta: {
        title: "首页",
        hidden: true,
      },
    },
    {
      path: "*",
      component: () => import("@/views/error/404"),
      hidden: true,
    },
  ],
});

/**
 * @description 附加请求系统信息
 * @param to 跳转到的route
*/
async function checkSystemInfo(to) {
  const meta = to.meta || {}
  const system = meta.system ?? false
  const systemConfig = store.state[SYSTEM_CONFIG];
  if (systemConfig) {
    return
  }

  if (!system) {
    return store.dispatch("GetSystemConfig")
  }

  await store.dispatch("GetSystemConfig")
}

/**
 * @description 登录前保存重定向路径
 * @param to 跳转到的route
*/
async function saveLoginRedirectPath(to, from) {
  if (to.path != '/login') {
    return storage.remove('r')
  }
  storage.set('r', from.fullPath.replace(/pass=/, 'op='))
}

/**
 * @description 通过passport登录保存token
 * @param to 跳转到的route
*/
async function savePassport(to) {
  const token = getToken() || store.getters.token;

  const pass = to.query?.pass || ''

  if (!pass) {
    return
  }

  if (pass == token) {
    return
  }

  store.commit('SET_TOKEN', pass)
  store.commit('SET_ROLES', [])
}


/**
 * @description 附加请求个人信息
*/
async function checkUserInfo() {
  const token = getToken() || store.getters.token;
  if (!token) {
    return;
  }

  const roles = store.getters.roles || [];
  if (roles.length > 0) {
    return
  }
  await store.dispatch("GetInfo").catch(() => store.dispatch("FedLogOut"))
}



/**
 * @description 附加请求招标会员信息
 * @param to 跳转到的route
*/
async function checkBidInfo(to) {
  const token = getToken() || store.getters.token;
  if (!token) {
    return;
  }

  if (!to.path.includes("/biddingProcurement/")) {
    return
  }
  await store.dispatch("GetBiddingAccount");
}

/**
 * @description 有token更正路径
 * @param to 跳转到的route
 * @param next
*/
async function correctRedirect(to, next) {
  const token = getToken() || store.getters.token;
  if (!token) {
    return next();
  }

  // 有token判断
  if (to.path != '/login') {
    return next();
  }

  next("/")
}

async function getEnv() {
  return request({ methods: 'get', url: '/web/getEnv' })
}

async function routerGuard(to, from, next) {
  getEnv();
  store.dispatch('GetProvinceList')
  store.dispatch('GetQualityStandardDict');//获取商品质量标准字典
  to.query?.i && storage.set('i', /^[0-9a-zA-Z]{4}$/.test(to.query?.i) ? to.query?.i : '')
  await saveLoginRedirectPath(to, from)
  await savePassport(to)
  await checkSystemInfo(to)
  await checkUserInfo()
  await checkBidInfo(to)
  await correctRedirect(to, next)
  next();
}

router.beforeEach(routerGuard);

router.afterEach(() => {
  event.emit('router:afterEach')
  const app = document.querySelector('#app')
  app && (app.scrollTop = 0);
})

export default router;
