<template>
    <el-dialog :visible.sync="open" width="650px" top="10vh" title="意见反馈" :close-on-press-escape="false"
        :close-on-click-modal="false" :destroy-on-close="true" custom-class="feedback-dialog dialog-wrapper" append-to-body
        @closed="resetForm">
        <el-form class="form requirement-form" ref="feedbackForm" :model="feedbackForm" label-width="200px"
            style="width: 590px" :rules="feedbackRules" label-suffix="：" label-position="right">
            <el-form-item label="问题类型" prop="typeValue">
                <el-select v-model="feedbackForm.typeValue" clearable placeholder="请选择问题类型">
                    <el-option v-for="feedbackType in dicts.feedback_type || []" :label="feedbackType.dictLabel"
                        :value="feedbackType.dictValue" :key="feedbackType.dictValue" />
                </el-select>
            </el-form-item>
            <el-form-item label="详细描述" prop="description">
                <el-input type="textarea" :rows="5" v-model="feedbackForm.description" :maxlength="500"
                    placeholder="请输入问题描述,不超过500字" />
            </el-form-item>
            <el-form-item label="相关图片" prop="imagesList">
                <el-upload action="/upload" accept="image/*" multiple list-type="picture-card" class="picture-upload"
                    :class="{ hide: feedbackForm.imagesList.length >= 8 }" :file-list="feedbackForm.imagesList" :on-success="(res, file, fileList) => (feedbackForm.imagesList = fileList)
                        " :on-remove="(file, fileList) => (feedbackForm.imagesList = fileList)">
                    <i class="el-icon-plus" />
                </el-upload>
                <div class="font14 mt10" style="color: #999;">最多上传8张图，单张图片大小不超过2M</div>
            </el-form-item>
            <el-form-item label="联系方式" prop="contactInformation">
                <el-input v-model="feedbackForm.contactInformation" placeholder="建议填写电话号码、邮箱等联系方式，方便我们与您联系" />
            </el-form-item>
        </el-form>
        <div class="btn-wrapper mt15 w100 flex v-center h-center">
            <el-button type="primary" style="width: 295px" :loading="saveLoading" @click.stop="handleSubmitFeedback">
                提交意见
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import code from '@/mixins/code';
import { cloneDeep } from 'lodash';
import feedbackApi from '@/api/feedback';

export default {
    mixins: [code],
    dicts: ['feedback_type'],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            feedbackForm: {
                contactInformation: "",
                description: "",
                imagesList: [],
                typeValue: ""
            },
            feedbackRules: {
                typeValue: [{ required: true, message: '请选择问题类型' }],
                description: [{ required: true, message: '请输入问题描述' }],
            },
            serviceTypeList: [],
            saveLoading: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
        open: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        async handleSubmitFeedback() {
            const isValid = await this.$refs.feedbackForm?.validate().catch(console.error);
            if (!isValid) {
                return
            }
            const params = cloneDeep(this.feedbackForm || {});
            params.images = params.imagesList.map(v => v.response).join(',');
            delete params.imagesList;
            this.saveLoading = true
            const saveRes = await feedbackApi.save(params).catch(console.error)
            this.saveLoading = false;
            if (!saveRes) {
                return
            }
            this.$message.success('提交成功')
            this.open = false;
        },
        resetForm() {
            this.feedbackForm = {
                contactInformation: "",
                description: "",
                imagesList: [],
                typeValue: ""
            };
            this.$refs.feedbackForm.clearValidate();
        },

    }
}
</script>

<style lang="less">
.feedback-dialog {
    .picture-upload {
        @imageW: 90px;

        .el-upload-list__item-status-label {
            display: none !important;
        }

        .el-upload--picture-card {
            width: @imageW !important;
            height: @imageW !important;
            line-height: @imageW !important;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: @imageW !important;
            height: @imageW !important;
            line-height: @imageW !important;
        }

        .el-progress.el-progress--circle {
            display: none !important;
        }

        &.hide {
            .el-upload--picture-card {
                display: none;
            }
        }
    }
}
</style>