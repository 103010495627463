<template>
  <div class="footer-wrapper">
    <div class="footer-view font12 color-white flex h-between mh-auto">
      <div class="flex col">
        <div class="title-view flex h-between font16" style="width: 740px">
          <div class="title pointer" @click.stop="handleToHome">首页</div>
          <div class="title pointer" @click.stop="scrollToTop">网站导航</div>
          <div
            class="title pointer"
            @click.stop="
              () => {
                winOpen('/estar/news');
                scrollToTop();
              }
            "
          >
            行业资讯
          </div>
          <div class="title pointer" @click.stop="handleShowRegister">会员注册</div>
          <div class="title pointer" @click="$router.push('/shop/open')">我要入驻</div>
          <div
            class="title pointer"
            @click.stop="
              () => {
                winOpen('/networkService');
                scrollToTop();
              }
            "
          >
            Saas服务
          </div>
          <div class="title pointer" @click.stop="winOpenName('ApplicationDownload')">
            应用下载
          </div>
        </div>
        <div
          v-if="externalLinkList && externalLinkList.length > 0"
          class="address-view flex font14 mt15 mb15"
          style="margin-top: 30px"
        >
          <span style="white-space: nowrap; line-height: 2">友情链接：</span>
          <div style="margin-right: 60px">
            <span
              v-for="(item, idx) in externalLinkList"
              :key="idx"
              :class="!!item.linkUrl ? 'link-hover' : ''"
              :style="{
                marginLeft: '20px',
                cursor: !!item.linkUrl ? 'pointer' : '',
                lineHeight: '2',
              }"
              @click="handleOpenDocument(item.linkUrl)"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
        <div
          class="address-view flex font12 mt10"
          :style="{
            marginTop: externalLinkList && externalLinkList.length > 0 ? '' : '30px',
          }"
        >
          <div>总部地址：深圳市南山区高新技术产业园北区朗山路16号华瀚创新园</div>
        </div>
        <div class="icp-view flex mt10 font12">
          <div
            class="pointer"
            @click.stop="
              handleOpenDocument(
                'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502000741'
              )
            "
          >
            粤公安备 44030502000741
          </div>
          <div
            class="ml10 pointer"
            @click.stop="
              handleOpenDocument('https://beian.miit.gov.cn/#/Integrated/index')
            "
          >
            粤ICP备16081673号 
          </div>
          <div class="ml10 pointer" @click.stop="handleOpenDocument('/dianxin.pdf')">
            电信增值业务经营许可证：粤 B2-20170236
          </div>
        </div>
        <div class="company-list-view flex mt10">
          <div
            class="company-item pointer"
            @click.stop="handleOpenDocument('/business_license.pdf')"
          >
            深圳前海优管信息技术有限公司
          </div>
        </div>
      </div>
      <div class="flex col">
        <div class="font16">24小时服务热线</div>
        <div class="font26 mt15" style="color: #f31e37">{{ systemConfig.phone }}</div>
        <div class="mt10">官方邮箱：{{ systemConfig.email }}</div>
        <div class="mt10">邮编：518057</div>
      </div>
    </div>
    <register v-model="showRegister" />
  </div>
</template>

<script>
import system from "@/mixins/system";
import Register from "@/components/business/register.vue";
import externalLinkApi from "@/api/externalLink";

export default {
  components: { Register },
  mixins: [system],
  data() {
    return {
      showRegister: false,
      externalLinkList: [],
    };
  },
  created() {
    this.getExternalLinkList();
  },
  methods: {
    getExternalLinkList() {
      externalLinkApi
        .list()
        .then((res) => {
          this.externalLinkList = res?.data || [];
          const len = this.externalLinkList.length;
          const app = document.getElementById("app");
          app.style.cssText = `--footer-height: ${len > 0 ? 256 : 180}px`;
        })
        .catch(() => {});
    },
    scrollToTop() {
      const app = document.getElementById("app");
      if (!app) {
        return;
      }
      app.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleToHome() {
      const currentPath = this.$route.path;
      if (currentPath === "/") {
        return this.scrollToTop();
      }
      this.winOpen("/");
      this.scrollToTop();
    },
    handleShowRegister() {
      this.showRegister = true;
    },
    handleOpenDocument(url) {
      url && this.winOpen(url);
    },
  },
};
</script>

<style lang="less" scoped>
.footer-wrapper {
  flex: none;
  min-height: var(--footer-height);
  width: 100vw;
  background-color: var(--footer-bg-color);
  position: relative;
  z-index: 1;

  .footer-view {
    width: var(--layout-width);
    height: inherit;
    padding: 30px 0px;
  }

  .link-hover:hover {
    text-decoration: underline;
  }
}
</style>
