import RouterLayout from "@/layout/router";
// 店铺
export default [
    {
        path: "/shop",
        component: RouterLayout,
        meta: {
            title: "商铺",
            icon: "dashboard",
            affix: true,
        },
        children: [
            {
                path: "",
                component: () =>
                    import(
                /* webpackChunkName: "shop-list" */ "@/views/shop/index"
                    ),
                name: "Shop",
                meta: {
                    title: "商铺列表",
                    icon: "dashboard",
                    affix: true,
                },
            },
            {
                path: "open",
                component: () =>
                    import(/* webpackChunkName: "shop-open" */ "@/views/shop/open"),
                name: "OpenShop",
                meta: {
                    title: "我要开店",
                    icon: "dashboard",
                    affix: true,
                },
            },
            {
                path: ":sid",
                component: RouterLayout,
                meta: {
                    title: "商铺详情",
                    icon: "dashboard",
                    affix: true,
                },
                children: [
                    {
                        path: '',
                        component: () =>
                            import(
                    /* webpackChunkName: "shop-detail" */ "@/views/shop/_id"
                            ),
                        name: "ShopDetail",
                        meta: {
                            title: "商铺详情",
                            icon: "dashboard",
                            affix: true,
                        },
                    },
                    {
                        path: "news",
                        component: RouterLayout,
                        meta: {
                            title: "新闻资讯",
                            icon: "dashboard",
                            affix: true,
                        },
                        children: [
                            {
                                path: "",
                                component: () =>
                                    import(/* webpackChunkName: "new-list" */ "@/views/news/index"),
                                name: "News",
                                meta: {
                                    title: "新闻资讯列表",
                                    icon: "dashboard",
                                    affix: true,
                                },
                            },
                            {
                                path: ":id",
                                name: "NewsDetail",
                                component: () =>
                                    import(
                                /* webpackChunkName: "news-detail" */ "@/views/news/_id"
                                    ),
                                meta: {
                                    title: "资讯详情",
                                    icon: "dashboard",
                                    affix: true,
                                },
                            },
                        ],
                    },
                ]
            },
        ],
    },
]