import { mapGetters } from "vuex"
import userApi from '@/api/user'
import { SYSTEM_CONFIG } from '@/store/constant'

export default {
    computed: {
        ...mapGetters(['isLogin'])
    },
    methods: {
        checkLogin(force = true) {
            if (!this.isLogin) {
                force && this.$message.warning('请先登录');
                force && this.$router.push('/login');
                return
            }
            return true
        },
        // 交易是否实名过
        async checkIdentify() {
            const res = await userApi.checkIdentity().catch(console.error)
            if (!res || !res.data) {
                this.$alert(`您未完成实名认证，请前往用户中心完成<a style="color: #F4552D; cursor:pointer; margin-left: 3px" id="identity-flag">实名认证</a>,若已实名认证,请重新登录`, '提示', {
                    dangerouslyUseHTMLString: true,
                    showConfirmButton: false,
                }).catch(console.error)
                setTimeout(() => {
                    const el = document.getElementById('identity-flag');
                    if (!el) {
                        return
                    }
                    el.addEventListener('click', this.handleClick)
                }, 100);
                return
            }
            return true
        },
        // 校验权限后做操作 force是否强制登录，未登录则跳转登录
        async done(fn, limits = 'login', force = true, ...args) {
            limits = limits === false ? '' : (limits || 'login');
            if (limits.indexOf('login') >= 0 && !this.checkLogin(force)) {
                return
            }
            console.log('limits', limits)
            if (limits.indexOf('identify') >= 0 && !await this.checkIdentify()) {
                return
            }
            if (fn) {
                return await fn(...args)
            }
        },
        toBack(path = "") {
            typeof path === "string" || (path = "");
            const address = this.$store.state[SYSTEM_CONFIG]?.address;
            if (!address) {
                return;
            }
            const token = this.$store.getters.token || ''
            path = path?.startsWith("/") ? path.slice(1) : path;
            path = address + '/' + path;

            if (token) {
                path += path.indexOf('?') >= 0 ? ('&pass=' + token) : ('?pass=' + token)
            }
            if (!path) {
                return;
            }
            this.winOpen(path);
        },
        handleClick() {
            this.toBack('/user/profile')
        }
    }
}