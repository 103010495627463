export const moduleMap = {
    Homepage: 0,
    Mall: 0,
    RetailMall: 0,
    PlasticTrade: 2,
    MachineDevice: 5,
    DigitalCity: 6,
};

export const mallModuleArr = ['RetailMall', '', 'PlasticTrade', '', '', 'MachineDevice', 'DigitalCity']
export const mallModulePathArr = ['retailMall', '', 'plasticTrade', '', '', 'machineDevice', 'digitalCity']

export const tabs = [
    "Homepage",
    "Mall",
    "Estar",
    "NetworkService",
]

export const tabNamesZh = {
    "Homepage": "首页",
    "Mall": "优管商城",
    "Estar": "优管荟",
    "NetworkService": "Saas服务",
}

export const kvDocArr = ['notice', 'rule', 'introduce', 'history']
