import estarRoutes from "./estar";
import mallRoutes from "./mall";

// 首页
const homeRoutes = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "homepage" */ "@/tabs/homepage"),
        name: "Homepage",
        meta: {
            title: "首页",
            icon: "dashboard",
            hidden: true,
            affix: true,
        },
    },
]

// SaaS服务
const networkRoutes = [
    {
        path: "/networkService",
        component: () => import(/* webpackChunkName: "network-service" */ "@/tabs/networkService"),
        name: "NetworkService",
        redirect: '/networkService/home',
        meta: {
            title: "Sass服务",
            icon: "dashboard",
            affix: true,
        },
        children: [
            {
                path: "home",
                component: () => import( /* webpackChunkName: "network-service-home" */ "@/views/networkService/index"),
                name: 'NetworkServiceHome',
                meta: {
                    title: "首页",
                    icon: "dashboard",
                    affix: true,
                }
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "network-service-detail" */"@/views/networkService/_id"),
                name: 'NetworkServiceDetail',
                meta: {
                    title: "详情",
                    icon: "dashboard",
                    affix: true,
                }
            }
        ]
    },
]


// 平台货源店铺商品
const supplyRoutes = [
  {
    path: "/platformSupply",
    component: () => import("@/tabs/platformSupply"),
    name: "PlatformSupply",
    redirect: '/platformSupply/home',
    meta: {
      title: "货源采购",
      icon: "dashboard",
      affix: true,
    },
    children: [
      {
        path: "home",
        component: () => import("@/views/platformSupply/index"),
        name: 'PlatformSupplyHome',
        meta: {
          title: "首页",
          icon: "dashboard",
          affix: true,
        }
      }
    ]
  },
]

// tabs 页面路由
export default [
    ...homeRoutes,
    ...mallRoutes,
    ...estarRoutes,
    ...networkRoutes,
    ...supplyRoutes
]
