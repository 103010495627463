import request from '@/utils/request'
export default {
    save(data) {
        return request({
            url: `/web/feedback/save`,
            method: "post",
            data
        })
    },
}
