export default {
    bind(el, binding) {
        el._visibleLoading = false;
        el._visibleObserver = new IntersectionObserver(async (entries) => {
            const entriesVisible = entries.filter(entry => entry.isIntersecting)
            if (entriesVisible.length > 0) {
                const fn = binding.value
                if (typeof fn === 'function' && !el._visibleLoading) {
                    el._visibleLoading = true;
                    await fn()
                }
            }
        })
        el._visibleObserver.observe(el)
    },
    unbind(el) {
        el._visibleObserver.disconnect()
        el._visibleObserver = null
    }
}