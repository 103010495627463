export default [
    // 购物车
    {
        path: "/cart",
        component: () =>
            import(/* webpackChunkName: "brand-list" */ "@/views/cart/index"),
        name: "cart",
        meta: {
            title: "购物车",
            icon: "dashboard",
            affix: true,
        },
    },
]