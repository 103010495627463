var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"footer-view font12 color-white flex h-between mh-auto"},[_c('div',{staticClass:"flex col"},[_c('div',{staticClass:"title-view flex h-between font16",staticStyle:{"width":"740px"}},[_c('div',{staticClass:"title pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleToHome.apply(null, arguments)}}},[_vm._v("首页")]),_c('div',{staticClass:"title pointer",on:{"click":function($event){$event.stopPropagation();return _vm.scrollToTop.apply(null, arguments)}}},[_vm._v("网站导航")]),_c('div',{staticClass:"title pointer",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.winOpen('/estar/news');
              _vm.scrollToTop();
            }).apply(null, arguments)}}},[_vm._v(" 行业资讯 ")]),_c('div',{staticClass:"title pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleShowRegister.apply(null, arguments)}}},[_vm._v("会员注册")]),_c('div',{staticClass:"title pointer",on:{"click":function($event){return _vm.$router.push('/shop/open')}}},[_vm._v("我要入驻")]),_c('div',{staticClass:"title pointer",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.winOpen('/networkService');
              _vm.scrollToTop();
            }).apply(null, arguments)}}},[_vm._v(" Saas服务 ")]),_c('div',{staticClass:"title pointer",on:{"click":function($event){$event.stopPropagation();return _vm.winOpenName('ApplicationDownload')}}},[_vm._v(" 应用下载 ")])]),(_vm.externalLinkList && _vm.externalLinkList.length > 0)?_c('div',{staticClass:"address-view flex font14 mt15 mb15",staticStyle:{"margin-top":"30px"}},[_c('span',{staticStyle:{"white-space":"nowrap","line-height":"2"}},[_vm._v("友情链接：")]),_c('div',{staticStyle:{"margin-right":"60px"}},_vm._l((_vm.externalLinkList),function(item,idx){return _c('span',{key:idx,class:!!item.linkUrl ? 'link-hover' : '',style:({
              marginLeft: '20px',
              cursor: !!item.linkUrl ? 'pointer' : '',
              lineHeight: '2',
            }),on:{"click":function($event){return _vm.handleOpenDocument(item.linkUrl)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"address-view flex font12 mt10",style:({
          marginTop: _vm.externalLinkList && _vm.externalLinkList.length > 0 ? '' : '30px',
        })},[_c('div',[_vm._v("总部地址：深圳市南山区高新技术产业园北区朗山路16号华瀚创新园")])]),_c('div',{staticClass:"icp-view flex mt10 font12"},[_c('div',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenDocument(
              'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502000741'
            )}}},[_vm._v(" 粤公安备 44030502000741 ")]),_c('div',{staticClass:"ml10 pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenDocument('https://beian.miit.gov.cn/#/Integrated/index')}}},[_vm._v(" 粤ICP备16081673号  ")]),_c('div',{staticClass:"ml10 pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenDocument('/dianxin.pdf')}}},[_vm._v(" 电信增值业务经营许可证：粤 B2-20170236 ")])]),_c('div',{staticClass:"company-list-view flex mt10"},[_c('div',{staticClass:"company-item pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenDocument('/business_license.pdf')}}},[_vm._v(" 深圳前海优管信息技术有限公司 ")])])]),_c('div',{staticClass:"flex col"},[_c('div',{staticClass:"font16"},[_vm._v("24小时服务热线")]),_c('div',{staticClass:"font26 mt15",staticStyle:{"color":"#f31e37"}},[_vm._v(_vm._s(_vm.systemConfig.phone))]),_c('div',{staticClass:"mt10"},[_vm._v("官方邮箱："+_vm._s(_vm.systemConfig.email))]),_c('div',{staticClass:"mt10"},[_vm._v("邮编：518057")])])]),_c('register',{model:{value:(_vm.showRegister),callback:function ($$v) {_vm.showRegister=$$v},expression:"showRegister"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }