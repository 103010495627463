// 订单
export default [
    {
        path: "/myPriceTrade",
        component: () =>
            import(
            /* webpackChunkName: "my-price-trade" */ "@/views/myPriceTrade/index"
            ),
        name: "MyPriceTrade",
        meta: {
            title: "我的询价",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/quote/list",
        component: () =>
            import(
            /* webpackChunkName: "quote-list" */ "@/views/myPriceTrade/quote/list"
            ),
        name: "MyQuoteList",
        meta: {
            title: "我的报价",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/myPriceTrade/details",
        component: () =>
            import(
            /* webpackChunkName: "my-trade-detail" */ "@/views/myPriceTrade/inquirySheet/details"
            ),
        name: "MyPriceTradeDetails",
        meta: {
            title: "我的询价详情",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/myPriceTrade/confirmForm",
        component: () =>
            import(
            /* webpackChunkName: "my-price-trade-confirm-form" */ "@/views/myPriceTrade/inquirySheet/confirmForm"
            ),
        name: "confirmForm",
        meta: {
            title: "确认合作",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/contractManage/online",
        component: () =>
            import(
            /* webpackChunkName: "contract-manage-online" */ "@/views/myPriceTrade/contractManage/online"
            ),
        name: "ContractManageOnline",
        meta: {
            title: "线上合同",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/contractManage/outline",
        component: () =>
            import(
            /* webpackChunkName: "contract-manage-outline" */ "@/views/myPriceTrade/contractManage/outline"
            ),
        name: "ContractManageOutline",
        meta: {
            title: "线下合同",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/contractManage/info",
        component: () =>
            import(
            /* webpackChunkName: "contract-manage-info" */ "@/views/myPriceTrade/contractManage/info"
            ),
        name: "ContractManageInfo",
        meta: {
            title: "合同详情",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/contractManage/infoConfirm",
        component: () =>
            import(
            /* webpackChunkName: "contract-manage-info-confirm" */ "@/views/myPriceTrade/contractManage/info_confirm"
            ),
        name: "ContractManageInfoConfirm",
        meta: {
            title: "确认合同",
            icon: "dashboard",
            affix: true,
        },
    },
    {
        path: "/inquiryOrder/edit",
        component: () =>
            import(
            /* webpackChunkName: "inquiry-order-edit" */ "@/views/myPriceTrade/inquiryOrder/edit"
            ),
        name: "inquiryOrderEdit",
        meta: {
            title: "订单修改",
            icon: "dashboard",
            affix: true,
        },
    },
]