const getters = {
  token: state => state.user.token,
  isLogin: state => !!state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userId: state => state.user.userId,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  accountId: state => state.user.accountId,
  user: state => state.user.user,
  eclubMember: state => state.user.eclubMember,
  isBidAccountDisabled: state => state.user.accountStatus != 0,
  isBidLogin: state => !!state.user.accountId,
  memberLevel: state => (state.user.user?.memberLevel || 0),
  memberLogo: state => state.user.user?.memberLevel > 0 ? state.user.user?.memberLogo : '',
}
export default getters
