import request from '@/utils/request'
export default {
  typeHome(data) {
    return request({
      url: "/web/internet/type/home",
      method: "post",
      data,
    });
  },
  typeDetail(data) {
    return request({
      url: "/web/internet/type/detail",
      method: "post",
      data,
    });
  },
  typeSearchDetail(params) {
    return request({
      url: "/web/internet/type/searchDetail",
      method: "get",
      params,
    });
  },
  typeList() {
    return request({
      url: `/web/internet/type/allService`,
      method: "get"
    });
  },
  saveRequirement(data) {
    return request({
      url: "/web/internet/customer/save",
      method: "post",
      data,
    });
  },
  createOrder(data) {
    return request({
      url: "/internet/payment/createOrder",
      method: "post",
      data,
    });
  },
  pay(data) {
    return request({
      url: "/internet/payment/pay",
      method: "post",
      data,
    });
  },
  verifySmsCode(data) {
    return request({
      url: "/web/internet/customer/verifySmsCode",
      method: "post",
      data,
    });
  },
  getSmsCode(params) {
    return request({
      url: "/web/internet/customer/getSmsCode",
      method: "get",
      params,
    });
  }
}
