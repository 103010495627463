import request from '@/utils/request'
export default {
    // 投放位广告  1 首页  2 商学院
    list(location) {
        return request({
            url: `/web/businessAd/page/${location}`,
            method: "get",
        })
    },
    advertiseList(data) {
      return request({
        url: `/web/pass/advertiseList`,
        method: "post",
        data
      })
    },
}
