import {
    Pagination,
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Alert,
    Icon,
    Row,
    Col,
    Upload,
    Progress,
    Rate,
    Steps,
    Step,
    Badge,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    Drawer,
    Cascader,
    Link,
    Divider,
    Image,
    CascaderPanel,
    Loading,
    MessageBox,
    Message,
    Notification,
    InfiniteScroll,
    Scrollbar,
    Avatar,
    Empty,
    Calendar,
} from 'element-ui';

import "element-ui/lib/theme-chalk/index.css";

export default function (Vue) {
    Vue.use(Cascader);
    Vue.use(CascaderPanel);
    Vue.use(Image);
    Vue.use(Avatar);
    Vue.use(Autocomplete);
    Vue.use(Empty)
    Vue.use(Pagination);
    Vue.use(Dialog);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Menu);
    Vue.use(Submenu);
    Vue.use(MenuItem);
    Vue.use(MenuItemGroup);
    Vue.use(Input);
    Vue.use(InputNumber);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Checkbox);
    Vue.use(CheckboxButton);
    Vue.use(CheckboxGroup);
    Vue.use(Drawer);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(OptionGroup);
    Vue.use(Button);
    Vue.use(ButtonGroup);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(DatePicker);
    Vue.use(Popover);
    Vue.use(Tooltip);
    Vue.use(Breadcrumb);
    Vue.use(BreadcrumbItem);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Tag);
    Vue.use(Alert);
    Vue.use(Icon);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Upload);
    Vue.use(Progress);
    Vue.use(Rate);
    Vue.use(Steps);
    Vue.use(Step);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(Link);
    Vue.use(Divider);
    Vue.use(Badge);
    Vue.use(Scrollbar);
    Vue.use(Calendar);
    Vue.use(Loading.directive);
    Vue.use(InfiniteScroll);
    Vue.prototype.$loading = Loading.service;
    MessageBox.setDefaults({
        confirmButtonClass: 'orange',
        cancelButtonClass: 'orange el-button--primary is-plain'
    })
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;



    //注意：页面this.$message
    Vue.prototype.$message = function (msg) {
        return Message({
            message: msg,
            duration: 5000
        })
    };
    //分别对success、warning和error等样式进行设置
    Vue.prototype.$message.success = function (msg) {
        return Message.success({
            message: msg,
            duration: 5000
        })
    };
    Vue.prototype.$message.warning = function (msg) {
        return Message.warning({
            message: msg,
            duration: 5000
        })
    };
    Vue.prototype.$message.error = function (msg) {
        return Message.error({
            message: msg,
            duration: 5000
        })
    }
}
