import { mapGetters } from 'vuex'
import storage from '@/utils/storage';
export default {
    computed: {
        ...mapGetters(['isLogin'])
    },
    methods: {
        openChat({ customerName, userId, productId }) {
            if (!this.isLogin) {
                this.$message.warning("请先登录");
                return this.$router.push("/login");
            }
            storage.set('chat_q', { customerName, userId, productId })
            this.winOpen(
                "/chat",
                "Chat_Room",
                `   menubar=no,
                    location=no,
                    resizable=no,
                    scrollbars=no,
                    status=no,
                    personalbar=no,
                    left=300,
                    top=150,
                    width=890,
                    height=700
                `
            );
        },
        openMyChat() {
            if (!this.isLogin) {
                this.$message.warning("请先登录");
                return this.$router.push("/login");
            }
            this.winOpen(
                "/my/chat",
                "Chat_Room",
                `   menubar=no,
                    location=no,
                    resizable=no,
                    scrollbars=no,
                    status=no,
                    personalbar=no,
                    left=300,
                    top=150,
                    width=890,
                    height=700
                `
            );
        },
    },
}