import axios from 'axios'
import { Message, Loading, Notification } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate, getType } from "@/utils";
import { saveAs } from 'file-saver'
import storage from './storage'
import store from '../store'
import cache from '@/plugins/cache'
import router from '../router'
import { ADDRESS } from '@/store/constant'

let downloadLoadingInstance;
// 是否显示重新登录
export const isRelogin = { show: false };

export function fetchFactory(baseURL, timeout = 10000) {
  const server = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL,
    // 超时
    timeout,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
  server.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    const address = store.state[ADDRESS] || '全国'
    config.params = getType(config.params) === 'Object' ? { address: address === '全国' ? null : address, ...config.params } : config.params
    config.data = getType(config.data) === 'Object' ? { address: address === '全国' ? null : address, ...config.data } : config.data

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
      const sessionObj = cache.session.getJSON('sessionObj')
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj)
      } else {
        const s_url = sessionObj.url;                  // 请求地址
        const s_data = sessionObj.data;                // 请求数据
        const s_time = sessionObj.time;                // 请求时间
        const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const message = '数据正在处理，请勿重复提交';
          console.warn(`[${s_url}]: ` + message)
          return Promise.reject(new Error(message))
        } else {
          cache.session.setJSON('sessionObj', requestObj)
        }
      }
    }
    return config
  }, error => {
    console.log(error)
    Promise.reject(error)
  })

  server.interceptors.response.use(res => {
    storage.remove('e/' + location.href);
    // 未设置状态码则默认成功状态
    const code = res.data?.code || 200;

    console.log('code', code)
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    if (code == 401) {
      removeToken();
      store.commit('SET_TOKEN', '');
      router.replace('/login')
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code == 500) {
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(msg))
    } else if (code != 200) {
      msg?.startsWith('登录超时') || msg?.startsWith('获取用户') || Notification.error({
        title: msg,
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
    error => {
      console.log('e', error)
      const e = storage.get('e/' + location.href) || 0
      if (e) {
        return Promise.reject(error)
      }
      let { message } = error;
      if (message == "Network Error") {
        message = "网络超时";
      } else if (message.includes("timeout")) {
        message = "系统繁忙，请稍后重试!";
      } else if (message.includes("Request failed with status code")) {
        console.warn("Request failed with status code", message)
        message = "系统异常，请稍后重试!";
      }
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
      })
      storage.set('e/' + location.href, 1);
      return Promise.reject(error)
    }
  )
  return server
}

const service = fetchFactory(process.env.VUE_APP_BASE_API)

// 通用下载方法
export async function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  try {
    const data = await service.post(url, params, {
      transformRequest: [(params_1) => { return tansParams(params_1) }],
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
      ...config
    })
    const isLogin = await blobValidate(data)
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text()
      const rspObj = JSON.parse(resText)
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error({ message: errMsg, duration: 5 * 1000 })
    }
    downloadLoadingInstance.close()
  } catch (r) {
    console.error(r)
    Message.error({ message: '下载文件出现错误，请联系管理员！', duration: 5 * 1000 })
    downloadLoadingInstance.close()
  }
}

export function upload(file) {
  console.log('upload', file)
  const fd = new FormData();
  fd.append('file', file);
  return axios({ url: '/upload', method: 'post', data: fd, headers: { "Content-Type": "multipart/form-data" } })
}

export function requestAlive(config, keepalive = true) {
  let { url, data, ...others } = config || {};
  const prefix = process.env.VUE_APP_BASE_API
  url = url.startsWith(prefix) ? url : `${prefix}${url}`
  const requestInfo = new Request(url)
  const token = getToken()
  const isPlainObject = Object.prototype.toString.call(data).slice(8, -1) === 'Object'
  return fetch(requestInfo, {
    ...others,
    body: isPlainObject ? JSON.stringify(data) : data,
    headers: { ...others.headers, 'Authorization': token ? `Bearer ${token}` : '', 'Content-Type': isPlainObject ? 'application/json' : 'text/plain;charset=UTF-8' },
    keepalive
  })
}

export default service
