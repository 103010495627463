import RouterLayout from "@/layout/router.vue";

// 优管荟
export default [
  {
    path: "/estar",
    component: () => import(/* webpackChunkName: "estar" */ "@/tabs/estar"),
    name: "Estar",
    redirect: "/estar/course",
    meta: {
      title: "优管荟",
      icon: "dashboard"
    },
    children: [
      {
        path: "eclub",
        component: RouterLayout,
        meta: {
          title: "eclub活动",
          icon: "dashboard",
          hidden: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            meta: {
              title: "eclub活动",
              hidden: true
            },
            redirect: "/estar/eclub/home",
            component: () =>
              import(
                /* webpackChunkName: "estar-eclub-home" */ "@/views/estar/eclub/index"
              ),
            children: [
              {
                path: "home",
                name: "EclubHome",
                meta: {
                  title: "线下活动",
                  icon: "dashboard"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "estar-eclub-home" */ "@/views/estar/eclub/home"
                  )
              },
              {
                path: ":id",
                name: "EclubDetail",
                meta: {
                  title: "eclub活动详情",
                  icon: "dashboard"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "estar-eclub-detail" */ "@/views/estar/eclub/_id"
                  )
              }
            ]
          }
        ]
      },
      {
        path: "course",
        component: RouterLayout,
        meta: {
          title: "课程",
          icon: "dashboard",
          hidden: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            meta: {
              title: "课程",
              hidden: true
            },
            redirect: "/estar/course/home",
            component: () =>
              import(
                /* webpackChunkName: "estar-course" */ "@/views/estar/course/index"
              ),
            children: [
              {
                path: "home",
                name: "CourseHome",
                meta: {
                  title: "培训课程",
                  icon: "dashboard"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "estar-course-home" */ "@/views/estar/course/home"
                  )
              },
              {
                path: ":id",
                name: "CourseDetail",
                meta: {
                  title: "课程详情",
                  icon: "dashboard"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "estar-course-detail" */ "@/views/estar/course/_id"
                  )
              }
            ]
          }
        ]
      },
      {
        path: "report",
        component: RouterLayout,
        meta: {
          title: "报告厅",
          icon: "dashboard",
          hidden: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            redirect: "/estar/report/home",
            meta: {
              title: "报告厅",
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "estar-report" */ "@/views/estar/report/index"
              ),
            children: [
              {
                path: "home",
                name: "ReportHome",
                meta: {
                  title: "行业报告",
                  icon: "dashboard"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "estar-report-home" */ "@/views/estar/report/home"
                  )
              },
              {
                path: ":code",
                name: "ReportDetail",
                meta: {
                  title: "报告详情",
                  icon: "dashboard"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "estar-report-detail" */ "@/views/estar/report/_code"
                  )
              }
            ]
          }
        ]
      },
      {
        path: "member",
        component: RouterLayout,
        meta: {
          title: "会员中心",
          icon: "dashboard",
          affix: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            name: "MemberHome",
            component: () =>
              import(
                /* webpackChunkName: "estar-member-home" */ "@/views/estar/member/index"
              )
          }
        ]
      },
      // 资讯
      {
        path: "news",
        component: RouterLayout,
        meta: {
          title: "行业资讯",
          icon: "dashboard",
          affix: true
        },
        children: [
          {
            name: "EstarNews",
            path: "/estar/news",
            meta: {
              title: "行业资讯",
              icon: "dashboard",
              affix: true
            },
            component: () =>
              import(
                /* webpackChunkName: "estar-news" */ "@/views/estar/news/index"
              )
          },
          {
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "estar-news-detail" */ "@/views/estar/news/_id"
              ),
            name: "EstarNewsDetail",
            meta: {
              title: "资讯详情",
              icon: "dashboard",
              affix: true
            }
          }
        ]
      },
      {
        path: "business",
        component: RouterLayout,
        meta: {
          title: "商机服务",
          icon: "dashboard",
          affix: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            meta: {
              title: "商机服务",
              hidden: true
            },
            redirect: "/estar/business/home",
            component: () => import("@/views/estar/business/index"),
            children: [
              {
                path: "home",
                name: "BusinessHome",
                meta: {
                  title: "商机服务",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/business/home")
              },
              {
                path: "list",
                name: "BusinessList",
                meta: {
                  title: "商机列表"
                },
                component: () => import("@/views/estar/business/list")
              },
              {
                path: ":id",
                name: "BusinessDetail",
                meta: {
                  title: "商机详情",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/business/_id")
              }
            ]
          }
        ]
      },
      {
        path: "answer",
        component: RouterLayout,
        meta: {
          title: "知识问答",
          icon: "dashboard",
          affix: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            meta: {
              title: "知识问答",
              hidden: true
            },
            redirect: "/estar/answer/home",
            component: () => import("@/views/estar/answer/index"),
            children: [
              {
                path: "home",
                name: "AnswerHome",
                meta: {
                  title: "知识问答",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/answer/home")
              },
              {
                path: "list",
                name: "AnswerList",
                meta: {
                  title: "知识问答列表"
                },
                component: () => import("@/views/estar/answer/list")
              },
              {
                path: ":id",
                name: "AnswerDetail",
                meta: {
                  title: "知识问答详情",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/answer/_id")
              }
            ]
          }
        ]
      },
      {
        path: "ency",
        component: RouterLayout,
        meta: {
          title: "产品百科",
          icon: "dashboard",
          affix: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            meta: {
              title: "产品百科",
              hidden: true
            },
            redirect: "/estar/ency/home",
            component: () => import("@/views/estar/ency/index"),
            children: [
              {
                path: "home",
                name: "EncyHome",
                meta: {
                  title: "产品百科",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/ency/home")
              },
              {
                path: "list",
                name: "EncyList",
                meta: {
                  title: "产品百科列表"
                },
                component: () => import("@/views/estar/ency/list")
              },
              {
                path: ":id",
                name: "EncyDetail",
                meta: {
                  title: "产品百科详情",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/ency/_id")
              }
            ]
          }
        ]
      },
      {
        path: "my",
        component: RouterLayout,
        meta: {
          title: "我的",
          icon: "dashboard",
          affix: true
        },
        children: [
          {
            path: "",
            icon: "dashboard",
            meta: {
              title: "我的",
              hidden: true
            },
            redirect: "/estar/my/courseOrder",
            component: () => import("@/views/estar/my/index"),
            children: [
              {
                path: "courseOrder",
                name: "CourseOrder",
                meta: {
                  title: "课程订单",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/my/courseOrder")
              },
              {
                path: "eclubOrder",
                name: "EclubOrder",
                meta: {
                  title: "活动订单",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/my/eclubOrder")
              },
              {
                path: "reportOrder",
                name: "ReportOrder",
                meta: {
                  title: "报告订单",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/my/reportOrder")
              },
              {
                path: "collect",
                name: "MyCollect",
                meta: {
                  title: "我的收藏",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/my/collect")
              },
              {
                path: "message",
                name: "MyMessage",
                meta: {
                  title: "我的留言",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/my/message")
              },
              {
                path: "browse",
                name: "MyBrowse",
                meta: {
                  title: "我的浏览",
                  icon: "dashboard"
                },
                component: () => import("@/views/estar/my/browse")
              }
            ]
          }
        ]
      }
    ]
  }
];
