import request from '@/utils/request'
export default {
    checkIdentity() {
        return request({
            url: "/system/user/checkIdentityFlag",
            method: "get",
        });
    },
    updateInfo(data) {
        return request({
            url: "/system/user/profile",
            method: "put",
            data,
        });
    },
    followMerchantSaveOrModify(data) {
        return request({
            url: "/web/follow/Merchant/saveOrModify",
            method: "post",
            data,
        });
    },

}