<template>
  <div class="comment-list-wrapper">
    <div
      v-for="(v, idx) in list"
      :key="v.id"
      :style="{ display: isAllHidden(v) ? 'none' : 'block' }"
    >
      <div
        class="mt5 ml30"
        @click="
          () => {
            $set(list[idx], 'canReply', true);
          }
        "
      >
        <span style="color: #f4552d">{{ v.userAccount }}</span>
        <i class="el-icon-caret-right"></i>
        <span style="color: #8a8a8a">{{ userName }}：</span>
        {{ v.hidden == 1 ? "评论不存在" : v.content }}
      </div>
      <div class="mt5 ml30" style="color: #aaa; font-size: 12px">
        {{ v.createTime ? $dayjs(v.createTime).format("YYYY-MM-DD HH: mm: ss") : "" }}
      </div>
      <div class="ml30">
        <el-input
          v-if="v.canReply"
          placeholder="请输入内容"
          v-model="content"
          v-focus
          class="input-with-select mt5"
        >
          <el-button
            slot="append"
            @click.stop="
              () => {
                if (!isLogin) {
                  $message.warning('请先登录');
                  return router.push('/login');
                }
                courseComment({
                  commentId: v.id,
                  courseId: v.courseId,
                  courseName: courseName,
                  content: content,
                  replyUserId: v.userId,
                  commentContent: v.content,
                });
                v.canReply = false;
              }
            "
          >
            回复
          </el-button>
          <el-button
            slot="append"
            class="cancel-button"
            @click.stop="
              () => {
                v.canReply = false;
                content = '';
              }
            "
          >
            取消
          </el-button>
        </el-input>
      </div>

      <course-comment
        :key="dateKey"
        :childList="v.childList || []"
        :userName="v.userAccount"
        :courseName="courseName"
        :courseId="courseId"
      />
    </div>
  </div>
</template>
<script>
import courseApi from "@/api/course";

export default {
  props: {
    childList: Array,
    userName: String,
    courseName: String,
    courseId: [Number, String],
  },
  data() {
    return {
      content: "",
      list: [],
      dateKey: Date.now(),
      commentList: [],
    };
  },
  watch: {
    childList: {
      handler(list) {
        this.list = list;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 发表评论
    async courseComment(data) {
      const commentContent = data.commentContent;
      const courseId = data.courseId;
      const commentId = data.commentId;
      const replyUserId = data.commentId;
      const courseName = data.courseName;
      const content = data.content.trim();
      if (!content) {
        this.$message.error("评论内容不能为空");
        return;
      }
      const params = {
        courseName,
        courseId,
        content,
        replyUserId,
        commentId,
        commentContent,
      };
      const res = await courseApi.courseComment(params).catch(console.error);
      await this.getCourseComment();
      if (res.code == 200 && res.message == "Success") {
        this.$message.success("发表评论成功");
      }
      this.dateKey = Date.now();
      this.content = "";
      this.$emit("refresh");
    },
    // 获取评论集合
    async getCourseComment() {
      const params = {
        courseId: this.courseId,
      };
      const resList = await courseApi.getCourseComment(params).catch(console.error);
      this.commentList = resList.data;
    },
    isAllHidden(item) {
      if (item.hidden != 1) {
        return false;
      }
      const childList = item.childList || [];
      for (let child of childList) {
        const childIsHidden = this.isAllHidden(child);
        if (!childIsHidden) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.comment-list-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }

  .el-input-group__append {
    .cancel-button {
      border-left: 1px solid #dcdfe6;
      border-radius: 0;
    }
  }
}
</style>
