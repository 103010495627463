// 订单
export default [
    {
        path: "/priceTrade",
        component: () =>
            import(
            /* webpackChunkName: "price-trade" */ "@/views/priceTrade/index"
            ),
        name: "PriceTrade",
        meta: {
            title: "询价交易",
            icon: "dashboard",
            affix: true,
        },
    },
]