import request from '@/utils/request'

export default {
  hotCourse(data = {}) {
    return request({
      url: "/web/course/hotCourse",
      method: "post",
      data
    })
  },
  categoryCourse(data = {}) {
    return request({
      url: "/web/course/categoryCourse",
      method: "post",
      data
    })
  },
  limitCoursePage(data = {}) {
    return request({
      url: "/web/course/limitCoursePage",
      method: "post",
      data
    })
  },
  // 资讯详情
  infoDetail(id) {
    return request({
      url: `/web/info/get/${id}`,
      method: "get",
    })
  },
  // 资讯类型
  getCategoryType(data) {
    return request({
      url: "/web/info-category/page",
      method: "post",
      data
    })
  },
  // 阅读量自增
  increasePageView(id) {
    return request({
      url: `/web/info/pageView/${id}`,
      method: "get",
    })
  },
  // 课程分页
  pageList(data) {
    return request({
      url: '/web/course/page',
      method: "post",
      data: { ...data, teachingMethod: 1, sourceType: 1 }
    })
  },
  // 课程分类
  typeList() {
    return request({
      url: '/web/business/course/category/list',
      method: "get",
      params: { type: 1 }
    })
  },
  unHomeTypeList() {
    return request({
      url: '/web/business/course/category/userLikelist',
      method: "get",
    })
  },
  // 课程讲师
  teacherWebList(data = {}) {
    return request({
      url: '/web/business/course/teacher/webList',
      method: "post",
      data
    })
  },
  // 课程弹窗接口
  courseWebList(data = {}) {
    return request({
      url: '/web/course/webList',
      method: "post",
      data
    })
  },
  // 二级课程分类
  childrenType(data) {
    return request({
      url: '/web/business/course/category/listById',
      method: "post",
      data
    })
  },
  // 课程详情
  getDetail(data) {
    return request({
      url: '/web/course/detail',
      method: "post",
      data
    })
  },
  // 课程是否试听
  isCourseAudition(data) {
    return request({
      url: '/courseAudition/isAudition',
      method: "post",
      data
    })
  },
  // 课程试听记录
  courseAuditionWebList(data) {
    return request({
      url: '/courseAudition/webList',
      method: "post",
      data
    })
  },
  // 课程试听编辑
  courseAuditionEdit(data) {
    return request({
      url: '/courseAudition/edit',
      method: "post",
      data
    })
  },
  // 课程是否报名
  isCourseApply(data) {
    return request({
      url: '/courseApply/isApply',
      method: "post",
      data
    })
  },
  // 课程报名记录
  courseApplyWebList(data) {
    return request({
      url: '/courseApply/webList',
      method: "post",
      data
    })
  },
  // 课程报名编辑
  courseApplyEdit(data) {
    return request({
      url: '/courseApply/edit',
      method: "post",
      data
    })
  },
  // 课程点赞
  courseLike(data) {
    return request({
      url: '/courseLike/add',
      method: "post",
      data
    })
  },
  // 阅读量自增
  coursePlayNum(data) {
    return request({
      url: '/coursePlayNum/add',
      method: "post",
      data
    })
  },
  // 课程收藏
  courseFavorite(data) {
    return request({
      url: '/courseFavorite/add',
      method: "post",
      data
    })
  },
  // 课程分享
  courseShare(data) {
    return request({
      url: '/courseShare/add',
      method: "post",
      data
    })
  },
  // 课程播放时长
  coursePlayTime(data) {
    return request({
      url: '/coursePlayTime/add',
      method: "post",
      data
    })
  },
  // 课程评论新增
  courseComment(data) {
    return request({
      url: '/courseComment/add',
      method: "post",
      data
    })
  },
  // 查询课程评论集合
  getCourseComment(data) {
    return request({
      url: `/web/courseComment/listByCourseId`,
      method: "post",
      data
    })
  },
  // 删除评论
  deleteComment(data) {
    return request({
      url: `/courseComment/web/delete`,
      method: "post",
      data
    })
  },
  // 评论列表
  commentPage(params = {}) {
    return request({
      url: `/courseComment/web/listByUserId`,
      method: "get",
      params
    })
  },
  // 评论列表
  coursePlayList(data = {}) {
    return request({
      url: `/coursePlayTime/listByUserId`,
      method: "post",
      data
    })
  },
  // 课程状态
  courseStatus(data) {
    return request({
      url: `/course/getLikeAndFavorite`,
      method: "post",
      data
    })
  },
  // 课程购买
  courseBuy(data = {}) {
    return request({
      url: `/courseBuy/add`,
      method: "post",
      data
    })
  },
  // 课程是否购买
  courseCheckBuy(data) {
    return request({
      url: `/courseBuy/isBuy`,
      method: "post",
      data
    })
  },
  // 课程收藏列表
  courseCollectList(params = {}) {
    return request({
      url: '/courseFavorite/list',
      method: 'get',
      params,
    })
  },
  // 课程购买列表
  courseBuyList(data = {}) {
    return request({
      url: '/courseBuy/buyList',
      method: 'post',
      data
    })
  },
  getMemberTypes(data) {
    return request({
      url: '/web/member/level/list',
      method: 'post',
      data: data
    })
  },
  getMemberLevelDetail(id) {
    return request({
      url: `/web/member/level/detail?id=${id}`,
      method: 'post',
    });
  },
  orderDelete(data = {}) {
    return request({
      url: `/courseOrder/deleteById`,
      method: "post",
      data
    });
  },
  orderCancel(data = {}) {
    return request({
      url: `/courseOrder/cancelOrder`,
      method: "post",
      data
    });
  },
  queryUserOrderStatus(data = {}) {
    return request({
      url: `/courseOrder/queryUserOrderStatus`,
      method: "post",
      data
    });
  },
  orderPageList(data = {}) {
    return request({
      url: `/courseOrder/page`,
      method: "post",
      data
    });
  },
  /**
   * 删除浏览记录
   * <pre>
   *   {
   *     "idList": [] // 记录id集合
   *   }
   * </pre>
   * @param data
   * @returns {*}
   */
  userDeleteHistory(data = {}) {
    return request({
      url: `/coursePlayNum/userDeleteHistory`,
      method: "post",
      data
    });
  },

}




