<template>
  <div
    class="tab-fixed-wrapper bg-white"
    :class="{ 'no-search': noSearch, logo: logo }"
    style="z-index: 11"
  >
    <div class="tab-wrapper pos-rel" @mouseleave="() => (showSubMenu = false)">
      <div class="tab-bg pos-abs bg-white full-bg" v-if="hasBg" />
      <div class="tab-view flex v-center pos-rel" :class="{ left: isLeft }">
        <div
          class="logo-wrapper pos-abs pointer"
          @click.stop="() => winOpen('/')"
        />
        <ul class="tab-items flex">
          <li
            class="tab-item"
            data-collected="homepageClassificationTab=1"
            :class="{ active: activeTab == 'Homepage' }"
            @click.stop="handleTabClick('Homepage')"
          >
            首页
          </li>
          <li
            class="tab-item"
            data-collected="homepageClassificationTab=1"
            :class="{ active: activeTab == 'Mall' }"
            @click.stop="handleTabClick('Mall')"
          >
            优管商城
          </li>
          <li
            class="tab-item"
            data-collected="homepageClassificationTab=7"
            :class="{ active: activeTab == 'Estar' }"
            @click.stop="handleTabClick('Estar')"
          >
            优管荟
          </li>
          <li
            class="tab-item"
            data-collected="homepageClassificationTab=6"
            :class="{ active: activeTab == 'NetworkService' }"
            @click.stop="handleTabClick('NetworkService')"
          >
            Saas服务
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { TAB } from "@/store/constant";
import { debounce } from "lodash";
import { tabs as tabList } from "@/utils/constant";

function isSameSet(prevTab, currTab) {
  const set = new Set(["NetworkService", "Homepage"]);
  return set.has(prevTab) && set.has(currTab);
}

export default {
  name: "TabBar",
  inject: ["updateQuery"],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    hasBg: {
      type: Boolean,
      default: true
    },
    classify: {
      type: Boolean,
      default: false
    },
    noSearch: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabList,
      showSubMenu: false,
      showClassify: true
    };
  },
  computed: {
    activeTab() {
      return this.$store.state[TAB];
    }
  },
  methods: {
    async handleTabClick(tabName) {
      if (isSameSet(this.activeTab, tabName)) {
        return this.$router.push({ name: tabName });
      }
      const path = this.$router.resolve({ name: tabName })?.route?.fullPath;
      path && this.winOpen(path, false);
    },
    toggleSubMenu(show) {
      this.showSubMenu = show;
    },
    handleBatchEnter() {
      this.toggleSubMenu(true);
    },
    watchAppScroll() {
      if (!this.classify) {
        return;
      }
      const app = document.getElementById("app");
      app?.addEventListener("scroll", this.appScrollEvent);
    },
    removeWatchScroll() {
      const app = document.getElementById("app");
      app?.removeEventListener("scroll", this.appScrollEvent);
    },
    appScrollEvent() {
      debounce(
        () =>
          (this.showClassify =
            document.getElementById("app")?.scrollTop <= 480),
        300
      )();
    }
  },
  mounted() {
    this.watchAppScroll();
  },
  beforeDestroy() {
    this.removeWatchScroll();
  },
  deactivated() {
    this.removeWatchScroll();
  },
  activated() {
    this.watchAppScroll();
  }
};
</script>

<style lang="less">
.tab-fixed-wrapper {
  padding-top: var(--tab-height);
  z-index: 11;

  .tab-bg {
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.4);
  }

  &.logo {
    padding-top: var(--search-height);

    .tab-wrapper {
      height: var(--search-height);

      .tab-view {
        padding-top: 0;

        .logo-wrapper {
          position: absolute;
          left: 0;
          top: 15px;
          background-image: url("~@/assets/images/site-logo.png");
          width: 200px;
          height: 64px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &.no-search {
    .tab-wrapper {
      top: var(--header-height);
    }
  }

  .tab-wrapper {
    height: var(--tab-height);
    position: fixed !important;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    top: calc(var(--header-height) + var(--search-height));

    .tab-view {
      width: var(--layout-width);
      height: inherit;
      z-index: 3;
      position: relative;
      padding-left: var(--classify-width);

      .tab-items {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        --g-w: 100px;
        --t-w: calc((100% - 5 * var(--g-w)) / 4);

        .tab-item {
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          line-height: var(--tab-height);
          text-align: center;
          margin-left: var(--g-w);
          padding: 0 15px;

          &:active,
          &:hover,
          &.active {
            color: #fa562c;
          }
          &:last-child {
            margin-right: var(--g-w);
          }
        }
      }

      &.left {
        padding-left: 0;
      }
    }
  }

  .tab-replace {
    width: 100%;
    height: var(--tab-height);
    z-index: -1;
    position: relative;
    background: transparent;
  }
}
</style>
