import request, { fetchFactory } from '@/utils/request'

const imRequest = fetchFactory(process.env.VUE_APP_IM_API)

export default {
    getContacts(data) {
        return request({
            url: `/web/chat/queryShopContacts`,
            method: "post",
            data,
        });
    },
    contactList(params) {
        return request({
            url: `/web/customer/getCustomerManagementByShopId`,
            method: "get",
            params,
        });
    },
    wordList() {
        return request({
            url: `/business/word/list`,
            method: "get",
        });
    },
    verifyRepeatScoring(params) {
        return request({
            url: `/user/evaluator/verifyRepeatScoring`,
            method: "get",
            params,
        });
    },
    handlerUserRating(data) {
        return request({
            url: `/user/evaluator/handlerUserRating`,
            method: "post",
            data,
        });
    },
    handlerUnReadList(data) {
        return imRequest({
            url: `/chat/message/handlerUnReadList`,
            method: "post",
            data
        });
    },
    queryChatContacts(params = {}) {
        return imRequest({
            url: `/chat/message/queryChatContacts`,
            method: "get",
            params
        });
    },
    getUnreadFlag(params) {
        return imRequest({
            url: `/chat/message/getUnreadFlag`,
            method: "get",
            params,
        });
    },
}