<template>
    <el-dialog :visible.sync="open" width="800px" top="10vh" title="提交需求" :close-on-press-escape="false"
        :close-on-click-modal="false" :destroy-on-close="true" custom-class="requirement-dialog dialog-wrapper"
        append-to-body @closed="resetForm">
        <el-form class="form requirement-form" ref="requirementForm" :model="requirementForm" label-width="200px"
            style="width: 590px" :rules="requirementRules" label-suffix="：" label-position="right">
            <el-form-item prop="phone" label="手机号">
                <el-input placeholder="请输入手机号" v-model="requirementForm.phone" :maxlength="11" clearable />
            </el-form-item>
            <el-form-item prop="code" label="短信验证码">
                <div class="flex">
                    <el-input placeholder="短信验证码" :maxlength="6" v-model="requirementForm.code" clearable />
                    <el-button type="primary" class="ml15 align-c flex0" style="width: 100px; padding: 12px 0" :disabled="sent ||
                        !requirementForm.phone ||
                        requirementForm.phone.length < 11
                        " @click.stop="handleSent(requirementForm.phone)">
                        <span v-if="sent">{{ seconds }}s</span>
                        <span v-else-if="sendCount <= 0">获取验证码</span>
                        <span v-else>再次发送</span>
                    </el-button>
                </div>
            </el-form-item>
            <el-form-item label="姓名" prop="userName">
                <el-input placeholder="姓名" :maxlength="32" v-model="requirementForm.userName" clearable />
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
                <el-input v-model="requirementForm.companyName" placeholder="公司名称" />
            </el-form-item>
            <el-form-item label="关注服务" prop="serviceTypeList">
                <el-checkbox-group size="mini" v-model="requirementForm.serviceTypeList" class="service-group">
                    <el-checkbox-button v-for="service in serviceTypeList" :label="service.id" :key="service.id">
                        {{ service.serviceName }}
                    </el-checkbox-button>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="采购需求" prop="need">
                <el-input type="textarea" :rows="5" v-model="requirementForm.need" :maxlength="500" />
            </el-form-item>
        </el-form>
        <div class="btn-wrapper mt15 w100 flex v-center h-center">
            <el-button type="primary" style="width: 295px" :loading="saveLoading" @click.stop="handleSubmitRequirement">
                提交需求
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import code from '@/mixins/code';
import lodash from 'lodash';
import networkServiceApi from '@/api/networkService';

export default {
    mixins: [code],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            requirementForm: {
                phone: "",
                companyName: "",
                need: "",
                serviceTypes: "",
                serviceTypeList: [],
                userName: "",
            },
            requirementRules: {
                phone: [
                    { required: true, message: '请输入手机号' }, {
                        async validator(_, phone) {
                            if (phone && !/^1[3456789]\d{9}$/.test(phone)) {
                                return Promise.reject("请输入正确手机号");
                            }
                            return Promise.resolve(true);
                        },
                        trigger: "blur",
                    }
                ],
                userName: [{ required: true, message: '请输入姓名' }],
                companyName: [{ required: true, message: '请输入公司名称' }],
                need: [{ required: true, message: '请输入需求' }],
                code: [{ required: true, message: '请输入验证码' }],
                serviceTypeList: [{ required: true, message: '请选择关注服务' }],
            },
            serviceTypeList: [],
            saveLoading: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
        open: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    watch: {
        open(show) {
            if (!show) {
                return
            }
            const user = this.user || {};
            console.log('user', this.user)
            this.requirementForm = { ...this.requirementForm, userName: user.userName || '', phone: user.phonenumber || '' };
            this.fetchServiceTypes();
        }
    },
    methods: {
        async send() {
            const phone = this.requirementForm.phone;
            await networkServiceApi.getSmsCode({ phone }).catch(console.error);
        },
        async fetchServiceTypes() {
            const res = await networkServiceApi.typeList().catch(console.error);
            if (!res) {
                return;
            }
            this.serviceTypeList = res.data || []
        },
        async handleSubmitRequirement() {
            const form = this.requirementForm || {};
            const { phone, code } = form;
            const isValid = await this.$refs.requirementForm?.validate().catch(console.error);
            if (!isValid) {
                return
            }
            this.saveLoading = true
            const verifyRes = await networkServiceApi.verifySmsCode({ phoneNum: phone, code }).catch(console.error)
            if (!verifyRes) {
                this.saveLoading = false;
                return
            }
            const params = lodash.cloneDeep(form) || {};
            params.serviceTypes = params.serviceTypeList.join(',');
            delete params.serviceTypeList;
            const saveRes = await networkServiceApi.saveRequirement(params).catch(console.error)
            this.saveLoading = false;
            if (!saveRes) {
                return
            }
            this.$message.success('需求提交成功')
        },
        resetForm() {
            this.requirementForm = {
                phone: "",
                companyName: "",
                need: "",
                serviceTypes: "",
                serviceTypeList: [],
                userName: "",
            };
        },

    }
}
</script>

<style lang="less">
.requirement-dialog {
    .service-group {
        .el-checkbox-button {
            margin: 5px;
            border: 1px solid #eee;
            line-height: 1;

            .el-checkbox-button__inner {
                border: none;
                border-radius: 0;
            }

            &.is-checked {
                background-color: #409EFF;
                border-color: #409EFF;
            }
        }

    }
}
</style>