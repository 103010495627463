import LemonIMUI from "lemon-imui";
import "lemon-imui/dist/index.css";
import LemonVideo from './components/video.vue';
import LemonProductLink from './components/productLink.vue';
import LemonFile from './components/file.vue';

export default function (Vue) {
    Vue.component('lemonMessageVideo', LemonVideo);
    Vue.component('lemonMessageProductLink', LemonProductLink);
    Vue.component('lemonMessageFile1', LemonFile);
    Vue.use(LemonIMUI);
}
