function stopCopy(el, deep = false) {
  const arr = [el];
  while (arr.length > 0) {
    const node = arr.shift();
    if (!node || !node.style) {
      continue;
    }
    node.style.cssText +=
      "user-select: none; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;";
    if (!node._co) {
      node._co = new MutationObserver(records => {
        console.log("co", records);
        const isContain =
          node.style?.cssText?.indexOf("user-select: none") > -1;
        if (isContain) {
          return;
        }
        node.style.cssText +=
          "user-select: none; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;";
      });
      node._co.observe(node, {
        attributes: true,
        attributeFilter: ["style"]
      });
    }
    arr.push(...node.childNodes);
  }
}

function removeOb(el) {
  const arr = [el];
  while (arr.length > 0) {
    const node = arr.shift();
    if (node._co) {
      node._co.disconnect();
      delete node._co;
    }
    arr.push(...node.childNodes);
  }
}

export default {
  componentUpdated(el) {
    setTimeout(() => stopCopy(el), 800);
  },
  unbind(el) {
    removeOb(el);
  }
};
