// 订单
export default [
    {
        path: "/order/submit",
        component: () =>
            import(
            /* webpackChunkName: "order-submit" */ "@/views/order/submit"
            ),
        name: "OrderSubmit",
        meta: {
            title: "提交订单",
            icon: "dashboard",
            affix: true,
        },
    },
]