import { timeout } from '@/utils'
export default {
    data() {
        return {
            sent: false,
            sendCount: 0,
            seconds: 60
        }
    },
    methods: {
        send() { console.error('请实现send方法调起接口') },
        async getSmsCode(phone) {
            if (!phone) {
                this.$message.warning('请输入手机号')
                throw '请输入手机号'
            }
            if (!/^1[3456789]\d{9}$/.test(phone)) {
                this.$message.warning('手机号不正确')
                throw '手机号不正确'
            }
            await this.send();
            return true
        },
        async handleSent(phone) {
            if (this.sent) {
                return;
            }
            this.sent = true;
            this.sendCount += 1;
            const res = await this.getSmsCode(phone).catch(console.error);
            if (!res) {
                return this.reset();
            }
            await this.codeCountDown();
            this.sent = false;
            this.seconds = 60;
        },
        /*验证码发送倒计时*/
        async codeCountDown() {
            if (this.seconds <= 0) {
                return;
            }
            await timeout(1000);
            this.seconds -= 1;
            await this.codeCountDown();
        },
        reset() {
            this.sent = false;
            this.seconds = 60;
            this.sendCount = 0;
        }
    }
}