import cooperationRoutes from './cooperation'
import docRoutes from './doc'
import orderRoutes from './order'
import shopRoutes from './shop'
import tabRoutes from './tabs'
import tradeRoutes from './priceTrade'
import priceTradeRoutes from './myPriceTrade'
import cartRoutes from './cart'
import applicationRoutes from './application'
import memberRoutes from './member'


export default [
    ...cooperationRoutes,
    ...docRoutes,
    ...orderRoutes,
    ...shopRoutes,
    ...tabRoutes,
    ...tradeRoutes,
    ...priceTradeRoutes,
    ...cartRoutes,
    ...applicationRoutes,
    ...memberRoutes,
]