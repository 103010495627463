import request from '@/utils/request'

export default {
    biddingAccountApply(data) {
        return request({
            url: "/web/bidding/account/audit/submit",
            method: "post",
            data,
        });
    },
    // 查询市
    getCityList(cityId) {
        return request({
            url: `/city/list/${cityId}`,
            method: "post",
        })
    },
    // 查询区/县
    getCountyList(countyId) {
        return request({
            url: `/county/list/${countyId}`,
            method: "post",
        });
    },
    // 招标项目-投标项目
    list(data) {
        return request({
            url: `/web/bidding/biddingHomePage`,
            method: "post",
            data
        });
    },
    // 招标公告
    noticeList(data) {
        return request({
            url: `/web/bidding/board/boardHomePage`,
            method: "post",
            data
        });
    },
    // 招标公告详情
    noticeDetail(data) {
        return request({
            url: `/web/bidding/board/detail`,
            method: "post",
            data
        });
    },
    // 会员信息
    account() {
        return request({
            url: `/web/bidding/account/getBiddingAccount`,
            method: "get"
        });
    },
    // 省市区接口
    provinceTree() {
        return request({
            url: `/web/provincial/tree`,
            method: "get"
        });
    },
    // 招标详情
    detail(data) {
        return request({
            url: `/web/bidding/biddingRecord`,
            method: "post",
            data
        });
    },
    // 投标报名
    sign(data) {
        return request({
            url: `/web/tender/register/register`,
            method: "post",
            data
        });
    },
    // 可用次数
    usable(data) {
        return request({
            url: `/bidding/usable/handelUsableNum`,
            method: "post",
            data
        });
    },
    // 购买可用次数
    usableInit(data) {
        return request({
            url: `/web/bidding/usable/usable/init`,
            method: "post",
            data
        });
    },
    // 查询可用次数
    queryUsable(data) {
        return request({
            url: `/web/bidding/usable/queryUsableNum`,
            method: "post",
            data
        });
    },
    // 购买标书
    tenderBuy(data) {
        return request({
            url: `/web/tender/buy/buyTender`,
            method: "post",
            data
        });
    },
    // 投标
    registerTender(data) {
        return request({
            url: `/tender/registerTender`,
            method: "post",
            data
        });
    },
    // 投标报名
    platformConfig() {
        return request({
            url: `/platform/sys/config/list`,
            method: "get",
        });
    },
    // 投标报名
    checkBidSignStatus(data) {
        return request({
            url: `/web/tender/register/CheckRegistrationStatus`,
            method: "post",
            data
        });
    },

}