import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import getters from './getters'
import {
  TAB,
  SET_TAB,
  KV_TAB,
  SET_KV_TAB,
  SET_SYSTEM_CONFIG,
  SYSTEM_CONFIG,
  SET_ADDRESS,
  ADDRESS
} from './constant';
import systemApi from '@/api/system';
import storage from '@/utils/storage';
import addressApi from '@/api/address';
import dictsApi from "@/api/dicts";
import { tabs as tabList } from '@/utils/constant';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
  },
  getters,
  state: {
    [TAB]: tabList[0],
    [KV_TAB]: '1',
    [SYSTEM_CONFIG]: '',
    [ADDRESS]: storage.get(ADDRESS) || '全国',
    provinceList: [],
    qualityStandardDict: [], //商品质量标准标签字典（如：未认证、国标、欧标等）
    greenBuildingMaterialDict: [], //店铺绿色建材标签字典（如：绿色建材一星、二星、三星）
  },
  mutations: {
    [SET_TAB](state, data) {
      state[TAB] = data;
    },
    [SET_KV_TAB](state, data) {
      state[KV_TAB] = data;
    },
    [SET_SYSTEM_CONFIG](state, data) {
      state[SYSTEM_CONFIG] = data;
    },
    [SET_ADDRESS](state, data) {
      state[ADDRESS] = data;
      storage.set(ADDRESS, data);
    },
    setProvinceList(state, data) {
      state.provinceList = data || []
    },
    setQualityStandardDict(state, data) {
      state.qualityStandardDict = data || {}
    },
    setGreenBuildingMaterialDict(state, data) {
      state.greenBuildingMaterialDict = data || {}
    }
  },
  actions: {
    async GetSystemConfig({ commit }) {
      const systemRes = await systemApi.getConfig().catch(console.error)
      if (!systemRes) {
        return
      }
      commit(SET_SYSTEM_CONFIG, systemRes.data)
    },
    async GetProvinceList({ state, commit }) {
      if (state.provinceList.length > 0) {
        return
      }
      const provinceRes = await addressApi.getProvinceList().catch(console.error)
      commit('setProvinceList', provinceRes?.data || [])
    },
    async GetQualityStandardDict({ state, commit }) {
      if (state.qualityStandardDict.length > 0) {
        return
      }
      const res = await dictsApi.getDicts("product_quality_standard").catch(console.error)
      commit('setQualityStandardDict', res?.data || [])
    },
    async GetGreenBuildingMaterialDict({ state, commit }) {
      if (state.qualityStandardDict.length > 0) {
        return
      }
      const res = await dictsApi.getDicts("shop_green_building_material").catch(console.error)
      commit('greenBuildingMaterialDict', res?.data || [])
    }
  },
});
