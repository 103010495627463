// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import component from "@/components";
import store from "@/store";
import plugins from "@/plugins";
import mixins from "@/mixins";
import common from "@/common";
import collection from "@/utils/collection";
import "@/assets/styles/global.less";
import htmlToPdf from '@/utils/htmlToPdf'

// 富文本组件
import Editor from "@/components/common/editor"
Vue.component('Editor', Editor)

Vue.use(htmlToPdf)
Vue.use(mixins);
Vue.use(component);
Vue.use(plugins);
Vue.use(common);
Vue.use(collection);
Vue.config.productionTip = false;

const webToH5Map = {
  '/product/([0-9]+)\\?*(.+)*': '/pages/product/detail?id=$1&$2',
}

function redirectH5() {
  const ua = navigator?.userAgent
  if (!/(ohos|iPhone|iPad|iPod|iOS|Android|HarmonyOS|huawei)/i.test(ua)) {
    return
  }
  const currentPath = location.href.replace(location.origin, '')
  const regKey = Object.keys(webToH5Map).find(path => new RegExp(path).test(currentPath))
  const h5Url = regKey ? currentPath.replace(new RegExp(regKey), webToH5Map[regKey]) : '/'
  location.href = `https://h5.epipe.cn/#${h5Url}`
}


/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  components: {
    App,
  },
  template: "<App/>",
});

redirectH5()
