import {
  login,
  logout,
  getInfo
} from '@/api/login'

import biddingProcurementApi from '@/api/biddingProcurement'
import {
  getToken,
  setToken,
  clearCookie
} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    userId: '',
    roles: [],
    accountId: '',
    accountStatus: '',
    user: '',
    eclubMember: ''
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      setToken(token)
    },
    SET_NAME(state, name) {
      state.name = name
    },
    SET_USER_ID(state, userId) {
      state.userId = userId
    },
    SET_ACCOUNT_ID(state, accountId) {
      state.accountId = accountId
    },
    SET_ACCOUNT_STATUS(state, accountStatus) {
      state.accountStatus = accountStatus
    },
    SET_AVATAR(state, avatar) {
      state.avatar = avatar
    },
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_CLUB_MEMBER(state, eclubMember) {
      state.eclubMember = eclubMember
    },
  },
  actions: {
    // 登录
    async Login({
      commit
    }, userInfo) {
      const userAccount = userInfo.userAccount.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid

      const params = {
        userAccount,
        password,
        code,
        uuid
      }
      const loginRes = await login(params).catch(console.error)
      if (!loginRes) {
        return
      }
      const token = loginRes.token || ''
      commit('SET_TOKEN', token)
      return token;
    },
    // 获取用户信息
    async GetInfo({
      commit, dispatch
    }) {
      const infoRes = await getInfo().catch(() => dispatch('FedLogOut'))
      if (!infoRes) {
        return
      }
      const {
        roles = [],
        user = {},
        eClubMember = {},
      } = infoRes || {}
      const avatar = !user.avatar ? require("@/assets/images/profile.svg") : user.avatar.startsWith('http') ? user.avatar : process.env.VUE_APP_BASE_API + user.avatar;
      commit('SET_ROLES', roles.length ? roles : [])
      commit('SET_NAME', user.userName || user.userAccount)
      commit('SET_USER_ID', user.userId)
      commit('SET_AVATAR', avatar)
      commit('SET_USER', { ...user, avatar })
      commit('SET_CLUB_MEMBER', eClubMember || {})
      return infoRes
    },
    async GetBiddingAccount({ commit, state }) {
      const token = state.token
      if (!token) {
        return
      }
      const biddingAccountRes = await biddingProcurementApi.account().catch(console.error)
      if (!biddingAccountRes) {
        return
      }
      const { id: accountId, status: accountStatus } = biddingAccountRes.data || {}
      accountId && commit("SET_ACCOUNT_ID", accountId)
      accountId && commit("SET_ACCOUNT_STATUS", accountStatus)
      return accountId
    },
    // 退出系统
    LogOut({
      commit,
      state
    }) {
      logout(state.token).catch(e => { throw e })
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_USER_ID', '')
      commit('SET_AVATAR', '')
      commit('SET_ROLES', [])
      commit("SET_ACCOUNT_ID", '')
      commit("SET_ACCOUNT_STATUS", '')
      commit("SET_USER", '')
      clearCookie()
      return true
    },
    // 前端 登出
    async FedLogOut({
      commit
    }) {
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_USER_ID', '')
      commit('SET_AVATAR', '')
      commit('SET_ROLES', [])
      commit("SET_ACCOUNT_ID", '')
      commit("SET_ACCOUNT_STATUS", '')
      commit("SET_USER", '')
      clearCookie()
      localStorage.removeItem('activityCurrentDate')
      localStorage.removeItem('coupon')
      return true
    }
  }
}

export default user
