import RouterLayout from "@/layout/router";

export default [
    // 会员
    {
        path: "/member",
        component: RouterLayout,
        redirect: '/member/center',
        meta: {
            title: "会员中心",
            icon: "dashboard",
            affix: true,
        },
        children: [
            {
                path: "center",
                name: "MemberCenter",
                component: () => import(/* webpackChunkName: "member-center" */"@/views/member/center.vue"),
                meta: {
                    title: "会员中心",
                    icon: "dashboard",
                    affix: true,
                }
            },
            {
                path: "products",
                name: "MemberProducts",
                component: () => import(/* webpackChunkName: "member-products" */"@/views/member/products.vue"),
                meta: {
                    title: "专享商品",
                    icon: "product",
                    affix: true,   
                }
            },
            {
                path: "my/equity",
                name: "MyMemberEquity",
                component: () => import(/* webpackChunkName: "my-member-equity" */"@/views/member/my/equity.vue"),
                meta: {
                    title: "我的权益",
                    icon: "money",
                    affix: true,
                }
            }
        ]
    },
]