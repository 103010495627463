import elementUI from './element-ui'
import directive from './directive'
import prototype from './prototype'
import lemonIMUI from './lemon-iMUI'

export default function (Vue) {
    Vue.use(elementUI)
    Vue.use(directive)
    Vue.use(prototype)
    Vue.use(lemonIMUI)
}