export default {
    /**
     * Sets a value in the local storage with an optional expiration time.
     *
     * @param {string} key - The key to set in the local storage.
     * @param {any} val - The value to set in the local storage.
     * @param {number} [ms=86400000] - The expiration time in milliseconds. Defaults to 15 days.
     */
    set(key, val, ms = 1000 * 60 * 60 * 24 * 15) {
        if (!key) {
            return
        }
        try {
            const expireTime = Date.now() + ms;
            localStorage.setItem(key, JSON.stringify({ data: val, expireTime }))
        }
        catch (e) {
            console.error(e)
        }
    },
    /**
     * Retrieves the value associated with the given key from local storage.
     *
     * @param {string} key - The key to retrieve the value for.
     * @param {boolean} [once=false] - If true, the value will be removed from local storage after retrieval.
     * @return {any} The value associated with the key, or undefined if the key does not exist or has expired.
     */
    get(key, once = false) {
        if (!key) {
            return
        }
        let val
        try {
            const resStr = localStorage.getItem(key) || '{"expireTime":0}'
            const { expireTime, data } = JSON.parse(resStr) || { expireTime: 0 }
            const now = Date.now()
            if (now > expireTime || once) {
                return this.remove(key)
            }
            val = data
        }
        catch (e) {
            console.error(e)
        }
        return val
    },
    /**
     * Removes an item from the local storage based on the provided key.
     *
     * @param {string} key - The key of the item to be removed.
     * @return {undefined} This function does not return a value.
     */
    remove(key) {
        if (!key) {
            return
        }
        localStorage.removeItem(key)
    },
    /**
     * Clears all items from the local storage.
     */
    clear() {
        localStorage.clear()
    }
}