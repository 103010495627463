import auth from "./auth";
import dicts from "./dicts";
import common from "./common";
import { tabs as tabList, tabNamesZh } from '@/utils/constant';
import { SET_TAB, TAB } from "@/store/constant";
import store from '@/store'
import lodash from 'lodash'
import VueQrcode from "vue-qrcode";

function throttleSendFn(vm) {
    lodash.throttle(() => {
        vm.$collection.send({ pv: true })
    }, 1000, { trailing: true, leading: false })()
}

function firstUpperCase(str = '') {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function (Vue) {
    Vue.mixin({
        components: { VueQrcode },
        computed: {
            tabbar() {
                return this.$store.state[TAB];
            },
            tabNameZh() {
                return tabNamesZh[this.tabbar]
            }
        },
        beforeRouteEnter(to, _, next) {
            const tabName = firstUpperCase(to.path.split("/").filter(v => !!v)[0]) || tabList[0];;
            if (tabList.findIndex((v) => tabName === v) < 0) {
                return next((vm) => vm.updateMeta());
            }
            store.commit(SET_TAB, tabName);
            next((vm) => {
                throttleSendFn(vm);
                vm.updateMeta()
            });
        },
        methods: {
            /**
             * @description 获取优管商城模块中文名称
            */
            getModuleNameZhByModule(module) {
                return ['管道交易', '批采拼单', '塑料贸易', '', '', '机电材料', '3C数码城'][module];
            },
            updateMeta() {
                const meta = this.$options.meta;
                const { title = '优管网-集管道行业供应链、产业链及创新链为一体的产业互联网交易平台', keywords = 'b2b，管道直销，塑料商品批发，管道生产设备，SaaS服务、供求商机、管道供应链，优管网', description = '优管网致力于服务中小微实体企业，以管道产品及其设备、塑料大宗类商品、智能制造和智慧城市应用技术三个专业领域为特色，让商家快速触达海量用户，让买家快速找到优质货源，构建集机构、企业、个人于一体的供应链交易平台。' } = meta || {}
                const titleEl = document.getElementsByTagName('title')[0] || document.createElement('title');
                const keywordsEl = document.querySelector('[name="keywords"]') || document.createElement('meta');
                const descriptionEl = document.querySelector('[name="description"]') || document.createElement('meta');
                keywordsEl.setAttribute('content', keywords)
                descriptionEl.setAttribute('content', description)
                titleEl.innerText = title;
                titleEl.remove();
                document.head.appendChild(titleEl);
                keywordsEl.remove();
                document.head.appendChild(keywordsEl);
                descriptionEl.remove();
                document.head.appendChild(descriptionEl);
            },
            updateTabBar(tabbar = "Homepage") {
                this.$store?.commit(SET_TAB, tabbar);
            },
            fetchFactory(api, resKey, pageTotalKey) {
                return async (params = {}, sortFn) => {
                    if (!api || typeof api != 'function') {
                        return
                    }
                    const listRes = await api(params).catch(console.error)
                    if (!resKey) {
                        return
                    }
                    if (!listRes) {
                        return;
                    }
                    if (!pageTotalKey) {
                        const list = listRes?.data || []
                        sortFn && list.sort(sortFn)
                        this[resKey] = list || []
                        return
                    }
                    let { list, total } = listRes?.data || {}
                    list = list || []
                    sortFn && list.sort(sortFn)
                    this[resKey] = list
                    this[pageTotalKey] = total || 0
                }
            },
            winOpen(url, ...args) {
                if (!url) {
                    return
                }
                if (url == '/' || url.startsWith('http')) {
                    return window.open(url)
                }
                const route = this.$router.resolve({ path: url.trim() })
                window.open(route.href, ...args)
            },
            winOpenName(name) {
                if (!name) {
                    return
                }
                const path = this.$router.resolve({ name }).href
                window.open(path)
            },
            winOpenLocation(location) {
                const path = this.$router.resolve({ ...location }).href
                window.open(path)
            },
            qrAppletAlert(appletPath, download = false) {
                if (!appletPath) {
                    return
                }
                const encodePath = encodeURIComponent(appletPath);
                const qrVal = `https://h5.epipe.cn/code?p=${encodePath}`;
                return this.qrAlert(qrVal, "请扫码进入小程序完成操作", download);
            },
            qrAlert(path, title = "请扫描二维码完成操作", download = false, options = {}) {
                if (!path) {
                    return
                }
                const h = this.$createElement;
                const res = this.$alert(
                    h(
                        "div",
                        {
                            style:
                                "display:flex; align-items:center; justify-content: center; flex-direction: column;",
                        },
                        [
                            ...(options?.beforeNodes || []),
                            title ? h("div", {
                                style: { fontSize: "14px", color: "#333", marginBottom: "15px" },
                                domProps: { innerHTML: title },
                            }) : '',
                            h(VueQrcode, { domProps: { id: 'qrcode' }, props: { margin: 0, width: options?.qrWidth || 170, value: path } }),
                            ...(options?.afterNodes || []),
                        ]
                    ),
                    { dangerouslyUseHTMLString: true, showConfirmButton: false, ...(options || {}) }
                ).catch(console.log);

                if (download) {
                    setTimeout(() => {
                        const a = document.createElement('a');
                        const img = document.getElementById('qrcode');
                        a.href = img.src;
                        a.download = `qrcode_${Date.now()}.png`;
                        a.click();
                        this.$message.success('二维码已下载');
                    }, 500);
                }

                return res;
            }
        },
        created() {
            this.$collection.init()
        },
        mounted() {
            const tabName = this.$route?.path.split("/").filter(v => !!v)[0] || tabList[0];
            this.updateTabBar(firstUpperCase(tabName))
        },
    })
    Vue.mixin(auth);
    Vue.mixin(dicts);
    Vue.mixin(common);
}
